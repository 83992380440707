import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetMyInterests,
  RemoveInterest,
} from "../../../store/Actions/InDirectService";
import "./WorkStatus.scss";
import Status from "./components/Status";
import Interests from "./components/Interests";
import RoomView from "../../../components/Services/RoomView";
import AirportAssistanceView from "../../../components/Services/AirportAssistanceView";
import RoomInterest from "../../../components/Interests/RoomInterest";
import AirportAssistanceInterest from "../../../components/Interests/AirportAssistanceInterest";
import Loading from "../../../components/samplePage/Loading";
import { toast } from "react-toastify";
import { IoAdd } from "react-icons/io5";
import { logoutUser } from "../../../store/Actions/User";
import { MdOutlineLogout } from "react-icons/md";
import SelectService from "../FindWork/components/SelectService";
import { useEffectWorkStatus } from "../hooks/useEffectWorkStatus";
import { useHandleWorkStatus } from "../hooks/useHandleWorkStatus";
import { PiWarningOctagonBold } from "react-icons/pi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaInfoCircle, FaAngleDoubleDown } from "react-icons/fa";
import { Alert } from "react-bootstrap";
import VerifiedStatus from "../../../components/common/VerifiedStatus";

export default function WorkStatus({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let query = new URLSearchParams(window.location.search);
  const service = query.get("service");
  const category = service.replaceAll(" ", "");

  const [status, setStatus] = useState(null);
  const completed = status == "completed" ? 1 : 0;
  const sortStatus = status == "completed" ? "accepted" : status;

  const user = useSelector((state) => state.user);
  const myInterests = useSelector((state) => state.myInterests);
  const userInfo = useSelector((state) => state.userInfo);
  const interest = useSelector((state) => state.interest);

  // useState for interests
  const [interests, setInterests] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [serviceView, setServiceView] = useState("");
  const [interestView, setInterestView] = useState("");
  const [error, setError] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [readMore, setReadMore] = useState(false);

  // functions
  const {
    logoutHandler,
    handleNavigation,
    handleServiceId,
    handleDelete,
    handleInterestView,
  } = useHandleWorkStatus({
    dispatch,
    logoutUser,
    navigate,
    setServiceId,
    RemoveInterest,
    setInterestView,
    setDeleteId,
    service,
    interests,
    category,
  });

  // useEffect
  useEffectWorkStatus({
    navigate,
    dispatch,
    setInterests,
    setServiceView,
    GetMyInterests,
    setError,
    setStatus,
    setInterests,
    location,
    service,
    sortStatus,
    completed,
    category,
    status,
    user,
    myInterests,
    interests,
    serviceId,
    query,
    toast,
    deleteId,
    interest,
  });

  return (
    <>
      {user.loading ? (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      ) : (
        <>
          {/*overveiw head*/}
          <div className="overveiw_cont findWorkCont">
            <div className="findWork_profile">
              <div className="flex items-center cursor-pointer">
                <img
                  onClick={() => navigate("/")}
                  className="rounded-circle w-[50px] h-[50px]"
                  src={userInfo.user?.profileImage}
                  alt="user"
                />

                <div className="flex items-center text-nowrap font-bold text-[17px] ml-2">
                  {userInfo.user?.name}
                  <VerifiedStatus isVerified={userInfo.user?.isVerified} />
                </div>
              </div>
              <div className="profileNavAlign text-white">
                <span
                  onClick={() =>
                    navigate(`/inDirectServicePage/${service}?status=pending`)
                  }
                  className="cursor-pointer"
                >
                  <IoAdd className="" />{" "}
                  <span className="findLarge ml-2">
                    {t("workStatus.Create")}
                  </span>
                </span>
                <IoMdNotificationsOutline className="profileNavAlign1 cursor-pointer" />
                <div
                  className="profileNavAlign2 homeAnchor p-2 cursor-pointer"
                  onClick={logoutHandler}
                >
                  <MdOutlineLogout className="my-2" />
                </div>
              </div>
            </div>

            {/*overveiw body*/}
            <SelectService
              t={t}
              service={service}
              navigate={navigate}
              status={status}
              selectClass={true}
            />
            <Status t={t} status={status} handleNavigation={handleNavigation} />

            <div className="p-3">
              <h2
                onClick={() => {
                  setReadMore(!readMore);
                }}
                className="flex items-center text-[#0cafff] mt-1 cursor-pointer "
              >
                <FaInfoCircle className="mr-2" />{" "}
                <span className="font-bold mr-2">{t("workStatus.Your")}</span>
                {status == "pending"
                  ? t("workStatus.pending")
                  : t("workStatus.accepted")}{" "}
                {t("workStatus.Responses")}
                {!readMore && (
                  <span className="flex items-center animate-bounce text-[black] text-[16px] mt-2">
                    <FaAngleDoubleDown className="ml-2" />
                  </span>
                )}
              </h2>

              <p className="text-secondary">
                {readMore && (
                  <span className="text-secondary text-[15px]">
                    {status == "pending" ? (
                      <>
                        {t("workStatus.Below, you'll find a list of")}{" "}
                        {`workStatus.${service}`} {t("responses you created")}
                        <br /> {t("once the response accepted you can chat")}.
                      </>
                    ) : (
                      <>
                        {t("workStatus.Below, you'll find a list of")}{" "}
                        {`workStatus.${service}`}{" "}
                        {t(
                          "workStatus.responses you created that are accepted"
                        )}{" "}
                        <br />
                        {t(
                          "Here, you can engage in conversation with them to ensure completion"
                        )}
                      </>
                    )}
                  </span>
                )}
              </p>
            </div>

            {/* error */}
            {error && (
              <Alert variant="danger" className="col-span-2 mt-3 mx-2">
                {error}
              </Alert>
            )}

            {/*content start*/}
            {myInterests.loading ? (
              <Loading type="colOne" color="#36d7b7" size={20} />
            ) : (
              <>
                {/*all interests*/}
                <Interests
                  t={t}
                  service={service}
                  status={status}
                  interests={interests}
                  handleServiceId={handleServiceId}
                  handleInterestView={handleInterestView}
                  handleDelete={handleDelete}
                  error={error}
                />
              </>
            )}
          </div>

          {/*service view*/}
          {service == "Room" ? (
            <RoomView
              serviceView={serviceView}
              handleServiceId={handleServiceId}
            />
          ) : service === "Airport assistance" ? (
            <AirportAssistanceView
              serviceView={serviceView}
              handleServiceId={handleServiceId}
            />
          ) : (
            <h3 className="text-danger">
              {t("workStatus.Select Valid Category")}
            </h3>
          )}

          {/*interest view*/}
          {service == "Room" ? (
            <RoomInterest
              interestView={interestView}
              handleInterestView={handleInterestView}
            />
          ) : service == "Airport assistance" ? (
            <AirportAssistanceInterest
              interestView={interestView}
              handleInterestView={handleInterestView}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
