import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordMailSend,
  loadUser,
  loginUser,
} from "../../store/Actions/User";
import Loading from "../../components/samplePage/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import HelmetDynamic from "../../utils/HelmetDynamic";
import { reactUrl, apiUrl, googleUrl } from "../../utils/apiUrl";
import { useEffectLogin, useLoginHandler } from "./useLoginHandler";
import { TiTick } from "react-icons/ti";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function LoginScreen({ handleLoginUserSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // selectors
  const user = useSelector((state) => state.user);
  const updateProfile = useSelector((state) => state.updateProfile);

  //useState
  const [file, setFile] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  // functions
  const { loginHandler, handleChange, handleForgetPassword, googleAuth } =
    useLoginHandler({
      t,
      setError,
      apiUrl,
      dispatch,
      loginUser,
      file,
      handleLoginUserSubmit,
      setFile,
      changePasswordMailSend,
      updateProfile,
    });

  const handleLogin = () => {
    window.location.href = googleUrl;
  };

  // useEffects
  useEffectLogin({
    location,
    dispatch,
    loadUser,
    user,
    navigate,
    updateProfile,
    file,
    setError,
  });
  return (
    <>
      <HelmetDynamic
        title={`Login - Stayforall`}
        description={
          "Welcome to Stayforall's User Portal. Login to access your account and discover resources to support international students and workers. Join Stayforall today to simplify your journey abroad."
        }
        url={`${reactUrl}/login`}
      />

      {user.loading || updateProfile.loading ? (
        <Loading type="hoverLoading" color="#36d7b7" size={120} />
      ) : (
        ""
      )}

      <IoIosArrowRoundBack
        onClick={() => navigate(-1)}
        className="absolute top-0 left-0 text-[40px] m-2"
      />
      <div className="registercont h-[100vh] w-[100vw]">
        <Col data-content={`loginpage`} className="registerconthead1">
          <img
            className="w-[150px]"
            src="/images/logo/SFAmediumLogo.webp"
            alt="SFAmediumLogo"
          />
          <div className="bg-[#ffffffd5] rounded text-secondary p-2 m-2">
            <h5 className="text-center font-bold text-black">
              {t("authentication.New to Stayforall?")}
            </h5>
            <p className="flex items-center">
              <TiTick className="mr-2 text-[#0CAFFF] text-[18px]" />
              {t(
                "authentication.Build your profile and find services like room, airport assistance,..."
              )}
            </p>
            <p className="flex items-center">
              <TiTick className="mr-2 text-[#0CAFFF] text-[18px]" />
              {t(
                "authentication.Get services interest from other users right after created service request"
              )}
            </p>
            <p className="flex items-center">
              <TiTick className="mr-2 text-[#0CAFFF] text-[18px]" />
              {t(
                "authentication.Help others and earn money by responding there service requests"
              )}
            </p>
            <Button
              onClick={() => navigate("/register")}
              variant=""
              style={{
                border: "1px solid #0CAFFF",
                borderRadius: "0px",
                padding: "5px 30px",
              }}
            >
              {t("authentication.Register for Free")}
            </Button>
          </div>
        </Col>
        <Col className="flex flex-column justify-around">
          <Form
            onSubmit={loginHandler}
            data-content="login"
            className=" registercontform"
          >
            <div className="flex flex-column items-center justify-center text-center pb-4 md:mt-5 sm:mt-0">
              <img
                className="w-[240px] pb-2"
                src="/images/logo/SFAlargeLogo.webp"
                alt="SFA large Logo"
              />
              <h5 className="setW font-bold">
                {t("authentication.Login to your Stayforall profile")}
              </h5>
              <p className="setW text-start text-secondary">
                {t(
                  "authentication.Create services and send response to services from germany's No.1 service provider"
                )}
              </p>
            </div>

            <p className="setP text-[#0CAFFF] text-[25px]">
              {t("authentication.Login to your Stayforall profile")}
            </p>

            <Form.Group className="my-3">
              <Form.Label className="font-bold md:text-[14px] sm:text-[18px] m-0 p-0">
                {t("authentication.Email ID")}
              </Form.Label>
              <div className="border rounded flex items-center p-1">
                <HiOutlineMail className="ml-2 text-[22px]" />
                <Form.Control
                  style={{ boxShadow: "none" }}
                  className="border-white "
                  onChange={handleChange}
                  type="email"
                  name="email"
                  value={file.email}
                  placeholder={t("authentication.Email ID")}
                  required
                />
              </div>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Label className="font-bold md:text-[14px] sm:text-[18px] m-0 p-0">
                {t("authentication.Password")}
              </Form.Label>
              <div className="border rounded flex items-center p-1">
                <RiLockPasswordLine className="ml-2 text-[22px]" />
                <Form.Control
                  style={{ boxShadow: "none" }}
                  className="border-white"
                  onChange={handleChange}
                  name="password"
                  value={file.password}
                  type="password"
                  placeholder={t("authentication.Password")}
                  required
                />
              </div>
            </Form.Group>
            <div className="text-end">
              <button
                type="reset"
                className="text-[13px] text-[#0CAFFF]"
                onClick={handleForgetPassword}
              >
                {t("authentication.Forgot password?")}
              </button>
            </div>
            <div className="text-danger my-2" id="error">
              {error}
            </div>

            <Button
              style={{ background: "#45b1e7", borderRadius: "3px" }}
              id="login_btn"
              type="submit"
              className="mb-3 px-5 shadow-md text-white"
              variant=""
            >
              {t("authentication.Login")}
            </Button>
          </Form>

          {/* <p className="setP text-[#0CAFFF] text-[25px]">
              Login to your Stayforall profile
            </p> */}

          <div>
            <div className="setP flex items-end justify-center">
              <Button
                onClick={() => navigate("/register")}
                variant=""
                style={{
                  border: "1px solid #0CAFFF",
                  borderRadius: "0px",
                  padding: "5px 20px",
                }}
              >
                {t("authentication.Register for Free")}
              </Button>
            </div>
            <div className="border-top my-3 mx-5 flex flex-column justify-center items-center text-center">
              <span className="px-1 bg-white translate-y-[-12px] text-secondary text-[15px]">
                {t("authentication.or")}
              </span>
            </div>
            <div className="flex justify-center">
              <span
                onClick={handleLogin}
                className="cursor-pointer flex items-center justify-center text-[14px] text-secondary p-2 border rounded-[14px]"
              >
                <FcGoogle className="mr-5" />{" "}
                {t("authentication.Sign in with Google")}
              </span>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
}
