import { createReducer } from "@reduxjs/toolkit";

export const blogsAndArticleReducer = createReducer(
  { loading: false, error: null, blogsAndArticle: null },
  (builder) => {
    builder
      .addCase("getallBlogsAndArticleRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.blogsAndArticle = null;
      })
      .addCase("getallBlogsAndArticleSuccess", (state, action) => {
        state.loading = false;
        state.blogsAndArticle = action.payload;
        state.error = null;
      })
      .addCase("getallBlogsAndArticleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.blogsAndArticle = null;
      })

      .addCase("getBlogsAndArticleRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.blogsAndArticle = null;
      })
      .addCase("getBlogsAndArticleSuccess", (state, action) => {
        state.loading = false;
        state.blogsAndArticle = action.payload;
        state.error = null;
      })
      .addCase("getBlogsAndArticleFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.blogsAndArticle = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
