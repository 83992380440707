import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const createDService = (file, serviceName) => async (dispatch) => {
  try {
    dispatch({
      type: "createDServiceRequest",
    });

    const { data } = await axios.post(
      `${apiUrl}/directService/${serviceName}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "createDServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "createDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const updateDService = (file, serviceName, id) => async (dispatch) => {
  try {
    dispatch({
      type: "updateDServiceRequest",
    });

    const { data } = await axios.put(
      `${apiUrl}/directService/${serviceName}/${id}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "updateDServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const deleteDService = (serviceName, id) => async (dispatch) => {
  try {
    dispatch({
      type: "DeleteDServiceRequest",
    });

    const { data } = await axios.delete(
      `${apiUrl}/directService/${serviceName}/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "DeleteDServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "DeleteDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const getDService = (serviceName) => async (dispatch) => {
  try {
    dispatch({
      type: "getDServiceRequest",
    });

    const { data } = await axios.get(
      `${apiUrl}/directService/myService/${serviceName}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getDServiceSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "getDServiceFailure",
      payload: error.response.data.message,
    });
  }
};
