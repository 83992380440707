import React, { useEffect, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MdDateRange } from "react-icons/md";
import { getNews } from "../../../store/Actions/News";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import { reactUrl } from "../../../utils/apiUrl";
import HelmetDynamic from "../../../utils/HelmetDynamic";

export default function NewsDetailedScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [news, setNews] = useState(null);
  const { allNews } = useSelector((state) => state.allNews);

  const handleShare = () => {
    if ("share" in navigator) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(t("infoPage.URL copied to clipboard"));
    }
  };

  // useEffects
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    dispatch(getNews(id));
  }, []);

  useEffect(() => {
    setNews(allNews);
  }, [allNews]);

  return (
    <>
      <HelmetDynamic
        title={`${news?.caption} - StayForAll News`}
        description={
          "StayForAll News - Explore insightful news on various topics."
        }
        url={`${reactUrl}/news-Stayforall/${id}`}
      />

      <Navigation />
      <div className="border mb-3"></div>
      <Container className="min-h-[80vh]">
        <Row className="flex justify-center items-start">
          <Col md={12}>
            <h2 className="newsDetails font-bold p-2">{news?.caption}</h2>
          </Col>

          <Col
            md={12}
            className="flex items-center justify-between text-end border-none rounded bg-[#bbbbbb3a] m-3 p-0"
          >
            <p className="flex items-center m-2">
              <MdDateRange className="mr-2" /> {news?.createdAt}
            </p>
            <p
              onClick={handleShare}
              className="cursor-pointer hover:scale-105 flex items-center m-2"
            >
              <IoShareOutline className="mr-2" /> {t("infoPage.share")}
            </p>
          </Col>
          <Col md={12} lg={6}>
            <LazyLoad height={200} offset={100} once>
              <img src={news?.image} alt="News" />
            </LazyLoad>
          </Col>
          <Col className="my-3">
            <p className="px-1">{news?.details}</p>
          </Col>
        </Row>
      </Container>

      <FooterTop bottom="bottom" />
    </>
  );
}
