import React from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

const HelmetDynamic = ({ title, description, url }) => {
  const sharedDescription =
    "Discover a wealth of indirect services tailored to the needs of international students and workers on StayForAll. From room listings and sublet opportunities to airport assistance and language learning programs, our platform offers comprehensive support to help you thrive in a global community. Explore our diverse range of services today";

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={sharedDescription} />
      <meta property="og:image" content="/images/logo/SFAlargeLogo.webp" />
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={sharedDescription} />
      <meta name="twitter:image" content="/images/logo/SFAlargeLogo.webp" />
      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />
      {/* Links */}
      <link rel="icon" type="image/png" href="/images/logo/SFAsmallLogo.webp" />
      <link rel="canonical" href={url} />
      {/* Scripts */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "WebPage",
          name: title,
          description: description,
          url: url,
        })}
      </script>
    </Helmet>
  );
};

HelmetDynamic.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default React.memo(HelmetDynamic);
