import { useEffect } from "react";

export const useEffectFindWork = ({
  user,
  dispatch,
  OthersInDService,
  service,
  state,
  city,
  uploadDate,
  moveInDate,
  rent,
  securityDeposit,
  serviceFee,
  propertyType,
  numberOfBedrooms,
  lengthOfLease,
  roomType,
  utilities,
  airportName,
  arrivalDate,
  payment,
  pageNumber,
  handleView,
  setServices,
  othersInDService,
  setState,
  userInfo,
  interest,
  setError,
  inDService,
  setInterestError,
  handleIds,
  query,
  navigate,
  location,
}) => {
  useEffect(() => {
    dispatch({ type: "clearErrors" });
    if (user.isAuthenticated) {
      dispatch(
        OthersInDService(
          service,
          state,
          service == "Airport Assistance" ? "" : city,
          uploadDate,
          pageNumber,
          rent,
          securityDeposit,
          moveInDate,
          serviceFee,
          propertyType,
          numberOfBedrooms,
          lengthOfLease,
          roomType,
          utilities,
          airportName,
          arrivalDate,
          payment
        )
      );
    }
    handleView("");
  }, [
    user,
    service,
    pageNumber,
    state,
    city,
    uploadDate,
    rent,
    securityDeposit,
    moveInDate,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
    airportName,
    arrivalDate,
    payment,
  ]);

  useEffect(() => {
    setServices(othersInDService.services);
  }, [othersInDService]);

  useEffect(() => {
    let url_state = query.get("state");
    if (!query.get("state")) {
      query.set("state", userInfo.user?.preferredState);
      navigate(`${location.pathname}?${query.toString()}`);
      setState(userInfo.user?.preferredState);
    } else setState(url_state);
  }, [userInfo.user]);

  useEffect(() => {
    if (interest.message === "Interest Created Successfully") {
      handleIds("");
    }
  }, [interest.message]);

  useEffect(() => {
    setError(inDService.error);
  }, [inDService.error]);

  useEffect(() => {
    setInterestError(interest.error);
  }, [interest.error]);

  return;
};
