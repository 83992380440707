import { createReducer } from "@reduxjs/toolkit";

export const WebinarReducer = createReducer(
  { loading: false, error: null, allWebinar: null },
  (builder) => {
    builder
      .addCase("getAllWebinarRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.allWebinar = null;
      })
      .addCase("getAllWebinarSuccess", (state, action) => {
        state.loading = false;
        state.allWebinar = action.payload;
        state.error = null;
      })
      .addCase("getAllWebinarFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allWebinar = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const WebinarNotifyReducer = createReducer(
  { loading: false, error: null, webinarNotify: null },
  (builder) => {
    builder
      .addCase("updateWebinarNotifyRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.webinarNotify = null;
      })
      .addCase("updateWebinarNotifySuccess", (state, action) => {
        state.loading = false;
        state.webinarNotify = action.payload;
        state.error = null;
      })
      .addCase("updateWebinarNotifyFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.webinarNotify = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
