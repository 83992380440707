import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const getAllWebinar = (page) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllWebinarRequest",
    });

    const { data } = await axios.get(`${apiUrl}/webinar/${page}`, {
      withCredentials: true,
    });
    dispatch({
      type: "getAllWebinarSuccess",
      payload: data.allWebinar,
    });
  } catch (error) {
    dispatch({
      type: "getAllWebinarFailure",
      payload: error.response.data.message,
    });
  }
};

export const updateWebinarNotify = (id, email) => async (dispatch) => {
  try {
    dispatch({
      type: "updateWebinarNotifyRequest",
    });

    const { data } = await axios.put(`${apiUrl}/updateNotify/${id}/${email}`, {
      withCredentials: true,
    });
    dispatch({
      type: "updateWebinarNotifySuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateWebinarNotifyFailure",
      payload: error.response.data.message,
    });
  }
};
