import React from "react";
import { MdDeleteOutline } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoChatboxOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { LuPhoneOutgoing } from "react-icons/lu";
import { PiWarningOctagonBold } from "react-icons/pi";
import VerifiedStatus from "../../../../components/common/VerifiedStatus";

export default function Interests({
  t,
  service,
  status,
  interests,
  handleServiceId,
  handleInterestView,
  handleDelete,
}) {
  const navigate = useNavigate();
  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: label1,
          onClick: clickHandler,
        },
        {
          label: label2,
        },
      ],
    });
  };
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      {interests?.length == 0 ? (
        <div className="col-span-3 flex justify-center items-center">
          <h6 className="flex flex-col font-[Helvetica] items-center rounded text-secondary bg-white shadow-md p-5">
            <PiWarningOctagonBold className="mb-2 text-[40px]" />{" "}
            {t("workStatus.No")} {t(`workStatus.${status}`)}{" "}
            {t(`workStatus.${service}`).toLowerCase()}{" "}
            {t("workStatus.response has been present")}
          </h6>
        </div>
      ) : (
        interests?.map((e, i) => (
          <div
            key={`interests${i}`}
            className="border rounded-[13px] bg-[white] m-2 mb-4 px-3 pb-3 shadow-lg"
          >
            <div className=" mt-2 flex justify-between items-center">
              <div className="flex">
                <div className="w-[12px] mx-2 rounded-[5px] bg-[orange]"></div>
                <div>
                  <div className="flex items-center text-nowrap font-bold text-[17px]">
                    {e?.name}
                    <VerifiedStatus isVerified={e?.isVerified} />
                  </div>
                  {/* <span className="font-bold">{e.name}</span> */}
                  <span className="flex items-center text-[#757575] text-center text-[12px]">
                    <BsCalendarDate className="mr-1  text-[12px]" />
                    {new Date(e.created_at).toLocaleDateString("en-GB", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                </div>
              </div>
              {status != "completed" && (
                <div
                  onClick={() => {
                    submit(
                      t("workStatus.Delete Interest"),
                      t("workStatus.Are you sure to Delete this interest"),
                      t("workStatus.Yes, I do"),
                      t("workStatus.No, I do'nt"),
                      () => handleDelete(e.interestId)
                    );
                  }}
                  className="cursor-pointer bg-[#fab2b28c] p-1 text-[18px] rounded-circle text-danger"
                >
                  <MdDeleteOutline className="" />
                </div>
              )}
            </div>

            <div className="text-center bg-[#e7e7e7] rounded-[10px] my-4">
              {service == "Room" ? (
                <>
                  <div className="flex text-center">
                    <div className="w-[50%] flex items-center  rounded-[10px] bg-[white] m-2 py-1">
                      <div className="flex items-start ">
                        <GiMoneyStack className="text-[30px] bg-[#84caf3a6] p-1 rounded-circle ml-3 mr-1" />
                        <div className=" flex flex-column items-center justify-center text-break">
                          <p className="flex p-0 m-0 text-[13px] text-secondary">
                            {t("workStatus.Rent")}
                          </p>
                          <h4>€ {e.rent_response}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center py-3 rounded-[10px] ">
                      <div className="flex items-start ">
                        <GiTakeMyMoney className="text-[30px] bg-[#9d90e9bb] p-1 rounded-circle ml-3 mr-1" />
                        <div className=" flex flex-column items-center justify-center text-break">
                          <span className="text-[13px] text-secondary">
                            {t("workStatus.Service fee")}
                          </span>
                          <span>€ {e.serviceFee_response}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : service == "Airport assistance" ? (
                <>
                  <div className="flex text-center">
                    <div className="w-[50%] flex items-center  rounded-[10px] bg-[white] m-2 py-1">
                      <div className="flex items-start ">
                        <LuPhoneOutgoing className="text-[30px] bg-[#84caf3a6] p-1 rounded-circle ml-3 mr-1" />
                        <div className=" flex flex-column items-center justify-center text-break">
                          <p className="flex p-0 m-0 text-[13px] text-secondary">
                            {t("workStatus.Contact Number")}
                          </p>
                          <h4>{e.primaryNumber}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center py-3 rounded-[10px] ">
                      <div className="flex items-start ">
                        <GiTakeMyMoney className="text-[30px] bg-[#9d90e9bb] p-1 rounded-circle ml-3 mr-1" />
                        <div className=" flex flex-column items-center justify-center text-break">
                          <span className="text-[13px] text-secondary">
                            {t("workStatus.Service fee")}
                          </span>
                          <span>€ {e.serviceFee_response}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="flex items-center justify-between">
              <div
                onClick={() => handleServiceId(e.serviceId)}
                className="cursor-pointer text-[#00800091] underline text-center text-[13px]"
              >
                {t(`workStatus.${service}`)}
              </div>
              <div>
                {status == "accepted" ? (
                  <div
                    onClick={() =>
                      navigate(`/chat/${service}/${e.interestId}/${e.owner_id}`)
                    }
                    className="flex items-center font-bold text-white cursor-pointer bg-[#4d7777] px-2 py-1 rounded"
                  >
                    <IoChatboxOutline className="hover:scale-105 mr-2" />
                    {t("workStatus.Chat Now")}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={() => handleInterestView(e.id)}
                className="flex flex-column items-center cursor-pointer "
              >
                <IoIosArrowRoundForward className="border rounded-circle p-1 text-[30px] bg-[#c9c9c9] text-[#4b4b4b]" />
                <span className=" text-[11px]">{t("workStatus.View")}</span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
