import { createReducer } from "@reduxjs/toolkit";

export const authChatReducer = createReducer(
  { loading: false, error: null, receiver: null },
  (builder) => {
    builder
      .addCase("authReceiverRequest", (state) => {
        state.loading = true;
        state.receiver = null;
        state.error = null;
      })
      .addCase("authReceiverSuccess", (state, action) => {
        state.loading = false;
        state.receiver = action.payload;
        state.error = null;
      })
      .addCase("authReceiverFailure", (state, action) => {
        state.loading = false;
        state.receiver = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const getAllMessagesReducer = createReducer(
  { loading: false, error: null, messages: null },
  (builder) => {
    builder
      .addCase("getAllMessageRequest", (state) => {
        state.loading = true;
        state.messages = [];
        state.error = null;
      })
      .addCase("getAllMessageSuccess", (state, action) => {
        state.loading = false;
        state.messages = action.payload;
        state.error = null;
      })
      .addCase("getAllMessageFailure", (state, action) => {
        state.loading = false;
        state.messages = [];
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const sendMessageReducer = createReducer(
  { loading: false, error: null, success: false },
  (builder) => {
    builder
      .addCase("sendMessageRequest", (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase("sendMessageSuccess", (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase("sendMessageFailure", (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const messageNotificationReducer = createReducer(
  { loading: false, error: null, messageNotifications: [] },
  (builder) => {
    builder
      .addCase("messageNotificationRequest", (state) => {
        state.loading = true;
        state.messageNotifications = [];
        state.error = null;
      })
      .addCase("messageNotificationSuccess", (state, action) => {
        state.loading = false;
        state.messageNotifications = action.payload;
        state.error = null;
      })
      .addCase("messageNotificationFailure", (state, action) => {
        state.loading = false;
        state.messageNotifications = [];
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
