import React, { useCallback, useRef } from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazyload";

const InDirectCategories = ({ t }) => {
  const navigate = useNavigate();

  // Refs
  const containerRef = useRef(null);
  const startRef = useRef(null);
  const draggingRef = useRef(false);

  // Handle mouse down for start sliding
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    draggingRef.current = false;
    startRef.current = e.clientX;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  // Handle mouse move for sliding
  const handleMouseMove = useCallback((e) => {
    draggingRef.current = true;
    const container = containerRef.current;
    const deltaX = e.clientX - startRef.current;
    startRef.current = e.clientX;

    // Calculate maximum and minimum scrollable positions
    const maxScrollLeft = 0;
    const minScrollLeft = container.offsetWidth - container.scrollWidth;

    let newLeft = parseFloat(container.style.left || 0) + deltaX;

    // Ensure newLeft stays within bounds
    newLeft = Math.max(minScrollLeft, Math.min(maxScrollLeft, newLeft));

    // Update container's left position
    container.style.left = `${newLeft}px`;
  }, []);

  // Handle mouse up for stop sliding
  const handleMouseUp = useCallback((e) => {
    if (!draggingRef.current) {
      const url =
        e.target.dataset.content === "Room verification"
          ? `/directServicePage/Room verification`
          : `/inDirectServicePage/${e.target.dataset.content}?status=pending`;
      navigate(url);
    }

    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }, []);

  // Data for services
  const services = [
    {
      title: "Room",
      image: "/images/homepage/room1.webp",
      avgRating: 5.0,
      avgPrice: 150,
      detailedDescription: "Room description",
      icon: "/images/icons/room_icon.png",
    },
    {
      title: "Airport assistance",
      image: "/images/homepage/airport1.webp",
      avgRating: 5.0,
      avgPrice: 100,
      detailedDescription: "Airport assistance description",
      icon: "/images/icons/airport_assistance_icon.png",
    },
    {
      title: "Room verification",
      image:
        "https://images.unsplash.com/photo-1518463732211-f1e67dfcec66?q=80&w=2030&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      avgRating: 5.0,
      avgPrice: 100,
      detailedDescription: "Room verification description",
      icon: "/images/icons/room_verification_icon.png",
    },
  ];

  return (
    <div id="InDirectId" className="categoryCont1 con2">
      <p className="indFirstHead text-center pt-4 font-bold">
        {t("homePage.InDirect Services title")}
      </p>
      <p className="flex items-center text-center mb-2 bg-[] text-[#6B5600] font-bold text-[18px]">
        {t("homePage.InDirect Services subtitle")}
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="button-tooltip-2">
              {t("homePage.InDirect Services Overlay")}
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <span
              variant="light"
              {...triggerHandler}
              className="d-inline-flex align-items-center"
            >
              <Image
                ref={ref}
                alt=""
                className="w-[21px] ml-2"
                src="/images/icons/questionMark.webp"
              />
            </span>
          )}
        </OverlayTrigger>
      </p>

      {/* Three categories */}
      <div className="cover_inD">
        <div
          id="serviceSlider"
          ref={containerRef}
          style={{ position: "relative", left: 0 }}
          onMouseDown={handleMouseDown}
          className="items text-black"
        >
          {services.map((item, i) => (
            <div
              key={`Services${i}`}
              className={`item item${i}`}
              data-content={item.title}
            >
              <div data-content={item.title} className="caroudeldiv">
                <h3 data-content={item.title} className="mb-2">
                  {t(`homePage.${item.title}`)}
                </h3>
                <LazyLoad height={200} offset={100} once>
                  <img
                    data-content={item.title}
                    className=""
                    src={item.image}
                    alt="Services"
                  />
                </LazyLoad>
                <p
                  data-content={item.title}
                  className="px-2 pt-[30px] pb-[10px] text-wrap text-[13px]"
                >
                  {t(`homePage.${item.detailedDescription}`)}
                </p>
              </div>
              <div className="servicetrans">
                <LazyLoad height={200} offset={100} once>
                  <img
                    data-content={item.title}
                    className="bg-light rounded-3xl"
                    src={item.icon}
                    alt="services title"
                  />
                </LazyLoad>
                <ul
                  data-content={item.title}
                  className="text-[#0F0C00] flex flex-column"
                >
                  <li data-content={item.title} className="font-bold pt-2">
                    {t(`homePage.${item.title}`)}
                  </li>
                  <li data-content={item.title} className="flex items-center">
                    <span
                      data-content={item.title}
                      className="flex items-center font-bold"
                    >
                      {item.avgRating}
                      <AiFillStar className="text-white" />
                    </span>
                    <span
                      data-content={item.title}
                      className="font-bold text-[#0F0C00] ml-5"
                    >
                      ~ €{Math.round(item.avgPrice)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(InDirectCategories);
