import React from "react";
import Room from "../Forms/Room";
import AirportAssistance from "../Forms/AirportAssistance";

export default function FormCategory({
  category,
  t,
  createFormHandler,
  userInfo,
  error,
  setError,
}) {
  let FormComponent;

  switch (category) {
    case "Room":
      FormComponent = Room;
      break;
    case "Airport assistance":
      FormComponent = AirportAssistance;
      break;
    default:
      return (
        <h3 className="text-danger">
          {t("inDirectServicePage.Select Valid Category")}
        </h3>
      );
  }

  return (
    <div id="forms" className="mainform">
      <FormComponent
        t={t}
        createFormHandler={createFormHandler}
        user={userInfo}
        error={error}
        setError={setError}
      />
    </div>
  );
}
