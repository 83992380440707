import React from "react";

export default function Status({ t, status, handleNavigation }) {
  return (
    <>
      <ul className=" sorting-container-wrapper pb-2">
        {["pending", "accepted"].map((e, i) => (
          <li
            key={`status${i}`}
            className={`${
              status == e ? "border-[2px] py-1" : "py-2"
            } flex items-center rounded bg-white mr-3 px-2 cursor-pointer text-[16px] shadow-sm`}
            onClick={() => handleNavigation(e)}
          >
            <img
              className="w-[13px] h-[13px] mr-2"
              src={`/images/icons/${e}.webp`}
              alt=""
            />
            {e == "accepted" ? t("workStatus.accepted") : t(`workStatus.${e}`)}
          </li>
        ))}
      </ul>
    </>
  );
}
