import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NewPasswordSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email } = useParams();
  return (
    <>
      <div>
        <section className="flex justify-between items-center py-3 px-1 border-bottom">
          <img
            className="w-[50px]"
            src="/images/logo/SFAmediumLogo.webp"
            alt="SFA LOGO"
          />
          <Button onClick={() => navigate("/")}>
            {t("authentication.Home")}
          </Button>
        </section>

        <section className="flex justify-center items-center mb-3">
          <div className="flex flex-column justify-center items-center max-w-[520px] border rounded my-3 mx-2 p-3">
            <img
              className="w-[100px]"
              src="/images/logo/SFAlargeLogo.webp"
              alt="SFA LOGO"
            />
            <h4 className="text-[32px]">{t("authentication.Success")}!</h4>
            <img
              className="w-[360px]"
              src="/images/loginpage/success.webp"
              alt="success"
            />
            <h5>{t("authentication.Password Changed")}</h5>
            <p>{t("authentication.Your Password Changed Successfully")}.</p>
            <Button onClick={() => navigate("/login")}>
              {t("authentication.Back to Login")}
            </Button>
          </div>
        </section>
      </div>
    </>
  );
}
