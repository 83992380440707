import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { GiButterfly } from "react-icons/gi";
import FooterTop from "../../../components/common/FooterTop";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../../../components/Navigation/Navigation";
import { GrLinkPrevious, GrLinkNext } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllBlogsAndArticles } from "../../../store/Actions/BlogsAndArticle";
import Loading from "../../../components/samplePage/Loading";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import HelmetDynamic from "../../../utils/HelmetDynamic";
import { reactUrl } from "../../../utils/apiUrl";
import "../Social.scss";

export default function BlogScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const blogsAndArticle = useSelector((state) => state.blogsAndArticle);
  const query = new URLSearchParams(location.search);
  const [blogs, setBlogs] = useState([]);
  const page = query.get("page") || 1;

  const handleUpdatePage = (newPage) => {
    navigate(`${location.pathname}?page=${newPage}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllBlogsAndArticles(page));
  }, [page, dispatch]);

  useEffect(() => {
    setBlogs(blogsAndArticle.blogsAndArticle);
  }, [blogsAndArticle]);

  return (
    <>
      <HelmetDynamic
        title={`StayForAll Blog - Explore Writings`}
        description={`Discover the latest blogs and articles from StayForAll. Explore topics for students and workers.`}
        url={`${reactUrl}/blogs-Stayforall?page=${page}`}
      />
      <Navigation />
      <Container className="py-10">
        <div className="text-center my-5">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.Writings from our team")}
            <GiButterfly className="ml-2" />
          </h1>
          <h6>{t("infoPage.The latest details for students and workers")}</h6>
        </div>

        <div className="flex flex-wrap min-h-[60vh] border-top border-bottom py-2">
          {blogsAndArticle.loading ? (
            <Loading type="servicePage" color="#36d7b7" size={120} />
          ) : (
            blogs?.map((blog, index) => (
              <div
                key={blog._id}
                onClick={() => navigate(`/blogs-Stayforall/${blog._id}`)}
                className="blog-each m-2"
              >
                <p>{`${t("infoPage.Blog")} ${index + 1}`}</p>
                <LazyLoad height={200} offset={100} once>
                  <img
                    className="rounded-top"
                    src={blog.image}
                    alt={`Blog ${index + 1}`}
                  />
                </LazyLoad>
                <div className="border rounded-b px-2">
                  <h4 className="text-[17px] mt-2">
                    {blog.caption.slice(0, 55)}...
                  </h4>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="mt-3 mb-4 flex justify-between items-center">
          <div
            onClick={() => handleUpdatePage(Math.max(1, Number(page) - 1))}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              page === "1" ? "hidden" : "flex"
            }`}
          >
            <GrLinkPrevious className="mr-2" />
            <span className="font-bold">{t("infoPage.Previous")}</span>
          </div>
          <div>{`${t("infoPage.Page")} ${page}`}</div>
          <div
            onClick={() => handleUpdatePage(Number(page) + 1)}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              blogs?.length < 12 ? "hidden" : "flex"
            }`}
          >
            <span className="font-bold">{t("infoPage.Next")}</span>
            <GrLinkNext className="ml-2" />
          </div>
        </div>
      </Container>
      <FooterTop bottom="bottom" />
    </>
  );
}
