import React, { useEffect, useState, useMemo } from "react";
import "./InDirectServicePage.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FooterTop from "../../components/common/FooterTop";
import { inDirectCategories } from "../../DataFiles/data";
import { useDispatch, useSelector } from "react-redux";
import LoginEachPage from "../../components/samplePage/LoginEachPage";
import Bubble from "./staticComponents/Bubble";
import SmallDescription from "./staticComponents/SmallDescription";
import Head from "./staticComponents/Head";
import NavigationInD from "./staticComponents/NavigationInD";
import Services from "./dynamicComponents/Services";
import Loading from "../../components/samplePage/Loading";
import CompleteSelect from "./staticComponents/completeSelect";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Nav, Row } from "react-bootstrap";
import Interests from "./dynamicComponents/Interests";
import ViewServiceInterest from "./dynamicComponents/ViewServiceInterest";
import FormCategory from "./staticComponents/FormCategory";
import { reactUrl } from "../../utils/apiUrl";
import HelmetDynamic from "../../utils/HelmetDynamic";
import { useLoadUser } from "../../hooks/useLoadUser";
import { useServiceFunctions } from "./hooks/useServiceFunctions";
import { useEffectService } from "./hooks/useEffectService";
import SmallScreenNav from "../../components/Navigation/components/SmallScreenNav";
import { FaHotel, FaHandsHelping } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";

const InDirectServicePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  // selectors
  const user = useSelector((state) => state.user);
  const inDService = useSelector((state) => state.inDService);
  const myInDService = useSelector((state) => state.myInDService);
  const othersInterests = useSelector((state) => state.othersInterests);
  const interest = useSelector((state) => state.interest);

  // state variables
  const [serviceView, setServiceView] = useState("");
  const [serviceIdSelect, setServiceIdSelect] = useState(
    query.get("service_id") || "default"
  );
  const [interestView, setInterestView] = useState("");
  const [interestIdSelect, setInterestIdSelect] = useState(
    query.get("interest_id") || ""
  );
  const [services, setServices] = useState([]);
  const [serviceInterests, setServiceInterests] = useState("");
  const [error, setError] = useState("");
  const [interestError, setInterestError] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [interestStatusSelect, setInterestStatusSelect] = useState("pending");
  const [savedUrl, setSavedUrl] = useState(null);
  const [newService, setNewService] = useState(false);
  const [completeSelect, setCompleteSelect] = useState(false);
  const [servicesCount, setServicesCount] = useState(0);

  // derived variables
  const category = useMemo(() => params.item, [params.item]);
  const serviceName = useMemo(() => category.replaceAll(" ", ""), [category]);
  const item = useMemo(() => inDirectCategories[serviceName], [serviceName]);
  const explain = useMemo(
    () => localStorage.getItem(`explain${serviceName}`),
    [serviceName]
  );
  let selectedService = useMemo(
    () => services?.find((e) => e.id == serviceIdSelect),
    [serviceIdSelect]
  );

  // hooks
  const { user: userData } = useLoadUser();

  // service functions
  const {
    handleServiceDelete,
    handleSwitchChange,
    createFormHandler,
    handleUpdateStatus,
    handleServiceIdSelect,
    handleServiceView,
    handleInterestView,
    handleCompleteSelect,
    handleServiceComplete,
    handleAcceptInterest,
    handleRejectInterest,
    resetServiceState,
    handleNextClick,
  } = useServiceFunctions({
    category,
    serviceName,
    serviceIdSelect,
    services,
    setServiceIdSelect,
    setServiceInterests,
    dispatch,
    setError,
    setInterestStatusSelect,
    setAccepted,
    accepted,
    query,
    location,
    setNewService,
    setServiceView,
    setInterestIdSelect,
    serviceInterests,
    setInterestView,
    interestIdSelect,
    setCompleteSelect,
    completeSelect,
  });

  useEffectService({
    dispatch,
    query,
    serviceName,
    status: query.get("status") || "",
    setError,
    category,
    user,
    newService,
    resetServiceState,
    location,
    setSavedUrl,
    setServices,
    setServicesCount,
    handleServiceIdSelect,
    savedUrl,
    serviceIdSelect,
    interestStatusSelect,
    inDService,
    setServiceInterests,
    othersInterests,
    explain,
    setServiceIdSelect,
    servicesCount,
    services,
    setCompleteSelect,
    serviceInterests,
    interest,
    setInterestView,
    interestIdSelect,
    setInterestError,
  });

  return (
    <>
      <HelmetDynamic
        title={`${category} - InDirect Services | StayForAll`}
        description={`Explore a diverse range of indirect services on StayForAll, designed to meet the needs of international students and workers. Discover room listings, sublet opportunities, airport assistance, language learning programs, team creation, skill finding, and group joining. Find the support you need to thrive in a global community.`}
        url={`${reactUrl}/inDirectServicePage/${category}?status=pending`}
      />

      <ToastContainer position="bottom-center" autoClose={2000} />

      {/* Rendering code start */}
      {user.loading ? (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      ) : (
        <>
          {!user.isAuthenticated && <LoginEachPage />}

          <div
            data-content={query.get("status")}
            className="maincontdet font-sans"
          >
            {query.get("status") == "form" ? (
              <NavigationInD t={t} />
            ) : (
              <div className={`navigation-container`}>
                <Nav className="largeNav shadow-md">
                  <SmallScreenNav
                    t={t}
                    navigate={navigate}
                    dispatch={dispatch}
                  />
                </Nav>
              </div>
            )}

            {query.get("status") === "form" && (
              <Head t={t} item={item} userData={userData} />
            )}

            {inDService.loading && (
              <Loading type="hoverLoading" color="#36d7b7" size={120} />
            )}

            <div className="">
              {query.get("status") === "form" ? (
                <FormCategory
                  category={category}
                  t={t}
                  createFormHandler={createFormHandler}
                  userInfo={userData}
                  error={error}
                  setError={setError}
                />
              ) : (
                <>
                  <div id="work-status">
                    <div className="formbub">
                      <div className="flex flex-column">
                        <div className="p-4">
                          <h2 className="text-2xl font-bold text-blue-600 flex items-center mb-4">
                            <FaHotel className="mr-2" />{" "}
                            {t("inDirectServicePage.Explore")}{" "}
                            {t(`inDirectServicePage.${category}`)}{" "}
                            {t("inDirectServicePage.options")}
                          </h2>
                          <p className="text-gray-700 mb-4">
                            {t("inDirectServicePage.Discover a variety of")}{" "}
                            {category}{" "}
                            {t(
                              "inDirectServicePage.options tailored to your preferences"
                            )}{" "}
                            {category.toLocaleLowerCase()}{" "}
                            {t("inDirectServicePage.needs")}.
                          </p>
                          <div className="flex items-center">
                            <button
                              id="currentNoteIndex1"
                              onClick={() => {
                                handleUpdateStatus("form");
                              }}
                              className={`border rounded p-2 bg-green-500 hover:bg-green-600 transition duration-200 flex items-center bg-white`}
                            >
                              <IoCreateOutline className="mr-2" />
                              {t("inDirectServicePage.Create a new request")}
                            </button>
                            <button
                              onClick={() =>
                                navigate(
                                  `/myProfile/find_work?service=${category}&pageNumber=1`
                                )
                              }
                              className="border rounded p-2 bg-green-500 hover:bg-green-600 transition duration-200 flex items-center ml-2 bg-white"
                            >
                              <FaHandsHelping className="mr-2" />{" "}
                              {t("inDirectServicePage.Help others to find")}{" "}
                              {category.toLocaleLowerCase()}
                            </button>
                          </div>
                        </div>

                        <div className="px-1 min-h-[60vh]">
                          <Services
                            t={t}
                            status={query.get("status")}
                            category={category}
                            services={services}
                            handleServiceIdSelect={handleServiceIdSelect}
                            serviceIdSelect={serviceIdSelect}
                            handleServiceView={handleServiceView}
                            handleServiceDelete={handleServiceDelete}
                            handleCompleteSelect={handleCompleteSelect}
                            accepted={accepted}
                            handleNextClick={handleNextClick}
                            myInDService={myInDService}
                            handleUpdateStatus={handleUpdateStatus}
                          />
                          <Interests
                            t={t}
                            serviceName={serviceName}
                            status={query.get("status")}
                            category={category}
                            serviceInterests={serviceInterests}
                            serviceIdSelect={serviceIdSelect}
                            handleInterestView={handleInterestView}
                            handleSwitchChange={handleSwitchChange}
                            interestStatusSelect={interestStatusSelect}
                            accepted={accepted}
                            error={error}
                            selectedService={selectedService}
                            handleNextClick={handleNextClick}
                          />
                          <ViewServiceInterest
                            category={category}
                            handleServiceView={handleServiceView}
                            handleInterestView={handleInterestView}
                            accepted={accepted}
                            serviceView={serviceView}
                            interestView={interestView}
                            handleAcceptInterest={handleAcceptInterest}
                            handleRejectInterest={handleRejectInterest}
                            interestError={interestError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {serviceIdSelect !== "" && completeSelect && (
                <CompleteSelect
                  t={t}
                  item={item.smallicon1}
                  category={category}
                  serviceInterests={serviceInterests}
                  handleCompleteSelect={handleCompleteSelect}
                  handleServiceComplete={handleServiceComplete}
                />
              )}

              <Bubble item={item} />
            </div>

            <SmallDescription t={t} item={item} />

            <FooterTop top="top" bottom="bottom" />
          </div>
        </>
      )}
    </>
  );
};

export default InDirectServicePage;
