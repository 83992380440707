import React, { useEffect, useRef, useState } from "react";
import "../../components.scss";
import { Link } from "react-router-dom";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaHandsClapping } from "react-icons/fa6";
import { interestNotification } from "../../../store/Actions/InDirectService";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../samplePage/Loading";
import { inDirectCategories } from "../../../DataFiles/data";

const ServiceNotification = ({ t, navigate }) => {
  const dispatch = useDispatch();
  const searchContainerRef = useRef(null);
  const searchIconRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const { interestNotifications, loading } = useSelector(
    (state) => state.getInterestNotification
  );

  useEffect(() => {
    dispatch(interestNotification);
  }, [dispatch]);

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target) &&
      searchIconRef.current &&
      !searchIconRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInterestNotification = (e) => {
    const { notification_type, service_type, service_id, interest_id } = e;
    switch (notification_type) {
      case "New Interest":
        navigate(
          `/inDirectServicePage/${service_type}?status=pending&service_id=${service_id}&interest_id=${interest_id}`
        );
        break;
      case "Interest Accepted":
        navigate(
          `/myProfile/workStatus?service=${service_type}&work_status=accepted`
        );
        break;
      case "Service Completed":
        navigate(
          `/myProfile/workStatus?service=${service_type}&work_status=completed`
        );
        break;
      default:
        break;
    }
  };

  const notificationCount = interestNotifications?.length || 0;

  return (
    <>
      <div ref={searchContainerRef}>
        {visible && (
          <div className="navNotification-drop">
            {loading ? (
              <Loading type="colOne" color="#36d7b7" size={20} />
            ) : notificationCount === 0 ? (
              <div className="flex flex-column items-center py-4">
                <h5 className="flex text-secondary">
                  <FaHandsClapping className="mr-2" />
                  {t("navigation.No New Notifications")}
                </h5>
              </div>
            ) : (
              <div className="p-2">
                {interestNotifications?.map((e) => (
                  <div
                    key={e.id}
                    onClick={() => handleInterestNotification(e)}
                    className="flex justify-between items-end hover:shadow-md text-black border-bottom p-1 pb-2 mb-2 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <img
                        className="rounded-circle w-[30px] h-[30px] mr-2"
                        src={
                          inDirectCategories[e.service_type.replaceAll(" ", "")]
                            ?.smallicon1
                        }
                        alt={e.service_type}
                      />
                      <ul className="m-0 p-0">
                        <li className="font-bold">{e.notification_type}</li>
                        <li className="text-[13px] text-secondary">
                          {t(`navigation.${e.service_type}`)}
                        </li>
                      </ul>
                    </div>
                    <p className="text-[13px] text-secondary">
                      {e.formatted_timestamp}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <Link
        aria-label="Notifications"
        to=""
        className="cursor-pointer text-[24px] m-[12px]"
        ref={searchIconRef}
        onClick={() => setVisible(!visible)}
      >
        <IoMdNotificationsOutline
          aria-label="notifications"
          className="search-icon"
        />
        {notificationCount > 0 && (
          <span className="text-[12px] text-white absolute bg-[#ff000098] px-[5px] rounded-circle translate-x-4 translate-y-[-30px]">
            {notificationCount}
          </span>
        )}
      </Link>
    </>
  );
};

export default ServiceNotification;
