import { useEffect } from "react";

export const useEffectWorkStatus = ({
  navigate,
  dispatch,
  setInterests,
  setServiceView,
  GetMyInterests,
  setError,
  setStatus,
  location,
  service,
  sortStatus,
  completed,
  category,
  status,
  user,
  myInterests,
  interests,
  serviceId,
  query,
  toast,
  deleteId,
  interest,
}) => {
  useEffect(() => {
    dispatch(GetMyInterests(service, sortStatus, completed));
  }, [category, status, completed]);

  useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(GetMyInterests(service, sortStatus, completed));
    }
  }, [user]);

  useEffect(() => {
    setInterests(myInterests.interests);
  }, [myInterests]);

  useEffect(() => {
    setServiceView(interests.find((e) => e.serviceId == serviceId));
  }, [serviceId]);

  useEffect(() => {
    let workStatus = query.get("work_status");
    setStatus(workStatus);
  }, [query]);

  useEffect(() => {
    if (interest.message == "Successfully Removed Interest") {
      toast.success("Response removed successfully!");
      setInterests(interests.filter((e) => e.interestId !== deleteId));
      dispatch({ type: "clearMessage" });
      dispatch({ type: "clearErrors" });
    }

    setError(interest.error);
  }, [interest.message, interest.error]);

  useEffect(() => {
    let newQuery = new URLSearchParams(window.location.search);
    newQuery.set("service", service);
    navigate(`${location.pathname}?${newQuery.toString()}`);
  }, [service]);

  return;
};
