import { Form, Button, Container, Alert } from "react-bootstrap";
import StepsOrder from "../components/StepsOrder";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { allCountries } from "../../../DataFiles/countries";
import { GrLinkPrevious } from "react-icons/gr";

export default function AdmissionAssistance({
  stringStep,
  error,
  setError,
  formSubmit,
}) {
  const [check, setCheck] = useState(false);
  const { user } = useSelector((state) => state.userInfo);

  const [fullName, setFullName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const [cgpa, setCgpa] = useState("");
  const [course, setCourse] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [preferredUniversity, setPreferredUniversity] = useState("");
  const [preferredCourse, setPreferredCourse] = useState("");
  const [detail, setDetail] = useState("");

  const [stage, setStage] = useState(0);

  useEffect(() => {
    setFullName(user?.name);
    setGender(user?.gender);
    setNationality(user.nativeCountry || "");
  }, [user]);

  const file = {
    fullName,
    dateOfBirth,
    gender,
    nationality,
    highestQualification,
    cgpa,
    course,
    courseDuration,
    preferredUniversity,
    preferredCourse,
    detail,
  };

  // submit the form
  const submitHandler = (e) => {
    e.preventDefault();

    formSubmit(file);
  };

  const isEmptyString = (value) => value === "";

  return (
    <div className="direct2nd">
      <Container className="directcont3">
        <StepsOrder
          step={stage}
          stringStep={stringStep}
          type={[
            t("directServicePage.Personal"),
            t("directServicePage.Education"),
            t("directServicePage.Admission"),
            t("directServicePage.Review"),
          ]}
        />

        <Container className="">
          <Form onSubmit={submitHandler} className="dform_size">
            {stage === 0 && (
              <div className="md:mt-2">
                <div className="flex items-center justify-between">
                  <h3 className="underline">
                    {t("directServicePage.PERSONAL DETAILS")}
                  </h3>
                </div>
                <div className="indirect_align gap-4">
                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Full Name")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border border-success rounded-[15px] px-2 py-1">
                      <Form.Control
                        className="bg-white border-0 shadow-none"
                        value={fullName}
                        type="text"
                        placeholder={t("directServicePage.Full Name")}
                        disabled
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.gender")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border border-success rounded-[15px] px-2 py-1">
                      <Form.Control
                        className="bg-white border-0 shadow-none"
                        value={gender}
                        type="text"
                        placeholder={t("directServicePage.gender")}
                        disabled
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Date Of Birth")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        value={dateOfBirth}
                        type="date"
                        max={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 15
                            )
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Nationality")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Select
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setNationality(e.target.value)}
                        defaultValue={nationality}
                      >
                        <option value="" hidden>
                          {t("directServicePage.Select")}
                        </option>
                        {allCountries.map((country, index) => (
                          <option key={`country${index}`} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>

                  <Form.Group className="col-span-2 w-[120px] mb-1 mt-3 border rounded p-1 bg-[#c9c9c9] text-[1.1em]">
                    <Form.Check
                      className="text-[13px]"
                      type="checkbox"
                      checked={check}
                      onChange={(e) => setCheck(e.target.checked)}
                      id="default-checkbox"
                      label={t("directServicePage.I'm not a robot")}
                    />
                  </Form.Group>
                  <Button
                    id="second_page_btn"
                    className="text-white font-bold px-5"
                    disabled={!check}
                    onClick={() => {
                      if (
                        isEmptyString(fullName) ||
                        isEmptyString(dateOfBirth) ||
                        isEmptyString(gender) ||
                        isEmptyString(nationality)
                      ) {
                        setError(
                          `${t("directServicePage.Enter full details")}`
                        );
                      } else {
                        setStage(1);
                        setError("");
                      }
                    }}
                  >
                    {t("directServicePage.Next")}
                  </Button>
                </div>
              </div>
            )}
            {stage === 1 && (
              <div className="md:mt-2">
                <div className="flex items-center justify-between">
                  <h3 className="underline">
                    {t("directServicePage.EDUCATION DETAILS")}
                  </h3>
                  <Button
                    className="font-bold mr-2"
                    onClick={() => {
                      setStage(0);
                    }}
                  >
                    <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                  </Button>
                </div>
                <div className="indirect_align gap-4">
                  <Form.Group className="col-span-2 my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Highest Qualification")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Select
                        className="border-0 shadow-none"
                        value={highestQualification}
                        onChange={(e) =>
                          setHighestQualification(e.target.value)
                        }
                        required
                      >
                        <option hidden value="">
                          {t("directServicePage.Select")}
                        </option>
                        {[
                          "Associate Degree",
                          "Bachelor's Degree",
                          "Master's Degrees",
                          "Doctoral Degrees",
                          "Professional Degrees",
                          "Specialized Degrees",
                          "Certificates and Diplomas",
                          "Honorary Degrees",
                          "Others",
                        ].map((qualification, index) => (
                          <option
                            key={`qualification${index}`}
                            value={qualification}
                          >
                            {t(`directServicePage.${qualification}`)}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>

                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Course Name")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setCourse(e.target.value)}
                        value={course}
                        type="text"
                        placeholder={t("directServicePage.Course Name")}
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.CGPA")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Ensure only decimal numbers between 0 and 10 are allowed
                          if (
                            value === "" ||
                            (Number(value) >= 0 && Number(value) <= 10)
                          ) {
                            setCgpa(value);
                          }
                        }}
                        value={cgpa}
                        type="number"
                        step="0.01" // Allows decimals
                        min="0"
                        max="10"
                        placeholder={t("directServicePage.CGPA")}
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="col-span-2 my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Course Duration")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Select
                        className="border-0 shadow-none"
                        value={courseDuration}
                        onChange={(e) => setCourseDuration(e.target.value)}
                        required
                      >
                        <option hidden value="">
                          {t("directServicePage.Select")}
                        </option>
                        {Array.from({ length: 4 }, (_, index) => index + 1).map(
                          (duration, index) => (
                            <option key={`duration${index}`} value={duration}>
                              {duration} {t("directServicePage.year")}
                            </option>
                          )
                        )}
                        <option value="more than 4 years">
                          {t("directServicePage.more than")} 4{" "}
                          {t("directServicePage.year")}
                        </option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <Button
                    id="second_page_btn"
                    className="text-white font-bold px-5 mt-2"
                    onClick={() => {
                      if (
                        isEmptyString(highestQualification) ||
                        isEmptyString(course) ||
                        isEmptyString(cgpa) ||
                        isEmptyString(courseDuration)
                      ) {
                        setError(
                          `${t("directServicePage.Enter full details")}`
                        );
                      } else {
                        setStage(2);
                        setError("");
                      }
                    }}
                  >
                    {t("directServicePage.Next")}
                  </Button>
                </div>
              </div>
            )}
            {stage === 2 && (
              <div className="md:mt-2">
                <div className="flex items-center justify-between">
                  <h3 className="underline">
                    {t("directServicePage.ADMISSION DETAILS")}
                  </h3>

                  <Button
                    className="font-bold mr-2"
                    onClick={() => {
                      setStage(1);
                    }}
                  >
                    <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                  </Button>
                </div>
                <div className="indirect_align gap-4">
                  <Form.Group className="col-span-2 my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Preferred University Type")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Select
                        className="border-0 shadow-none"
                        value={preferredUniversity}
                        onChange={(e) => setPreferredUniversity(e.target.value)}
                        required
                      >
                        <option hidden value="">
                          {t("directServicePage.Select")}
                        </option>
                        {[
                          "Community Colleges",
                          "Liberal Arts Colleges",
                          "Public Universities",
                          "Private Universities",
                          "Research Universities",
                          "Teaching Universities",
                          "Online Universities",
                          "Technical and Vocational Colleges",
                          "Art and Design Schools",
                          "Business Schools",
                          "Engineering Schools",
                          "Medical Schools",
                          "Law Schools",
                          "Graduate Schools",
                          "Community and Technical Colleges",
                          "Honors Colleges",
                          "Specialized Institutes",
                          "Others",
                        ].map((university, index) => (
                          <option key={`university${index}`} value={university}>
                            {t(`directServicePage.${university}`)}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>

                  <Form.Group className="col-span-2 my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Preferred Course")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setPreferredCourse(e.target.value)}
                        value={preferredCourse}
                        type="text"
                        placeholder={t("directServicePage.Preferred Course")}
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="col-span-2 my-1">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t("directServicePage.Details")}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{
                          border: "none",
                          boxShadow: "none",
                          height: "100px",
                        }}
                        onChange={(e) => setDetail(e.target.value)}
                        value={detail}
                        as="textarea"
                        placeholder={t(
                          "directServicePage.Short description about your preference"
                        )}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Button
                    id="second_page_btn"
                    className="text-white font-bold px-5 mt-2"
                    onClick={() => {
                      if (
                        isEmptyString(preferredUniversity) ||
                        isEmptyString(preferredCourse)
                      ) {
                        setError(
                          `${t("directServicePage.Enter full details")}`
                        );
                      } else {
                        setStage(3);
                        setError("");
                      }
                    }}
                  >
                    {t("directServicePage.Next")}
                  </Button>
                </div>
              </div>
            )}
            {stage === 3 && (
              <>
                <div className="mb-6 p-4 bg-white">
                  <div className="flex items-center justify-between">
                    <h3 className="underline">
                      {t(
                        "directServicePage.LET'S REVIEW YOUR ADMISSION DETAILS"
                      )}
                    </h3>

                    <div>
                      <Button
                        className="font-bold mr-2"
                        onClick={() => {
                          setStage(2);
                        }}
                      >
                        <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                      </Button>
                    </div>
                  </div>
                  <p className="mb-4">
                    {t("directServicePage.Submit the form, and our executives")}
                  </p>

                  <Form.Group>
                    <div className="accPay grid grid-cols-1 gap-4 mb-4">
                      <div className="flex justify-between items-center">
                        <h5 className="font-medium">
                          {t("directServicePage.Initial Set-up Fee")}
                        </h5>
                        <h5 className="font-medium text-right">€250</h5>
                      </div>
                      <div className="flex justify-between items-center">
                        <h5 className="font-medium">
                          {t("directServicePage.On Process Complete")}
                        </h5>
                        <h5 className="font-medium text-right">€1500</h5>
                      </div>
                    </div>

                    <div className="accPay p-4 bg-gray-50 rounded-md">
                      <h5 className="font-bold mb-2">
                        {t("directServicePage.Provides")}
                      </h5>
                      <ul className="list-disc pl-5 space-y-2 text-gray-700">
                        {[
                          "Informational Content",
                          "Application Process Overview",
                          "Program and Course Information",
                          "Admission Criteria",
                          "Application Forms and Downloads",
                          "Contact Information",
                          "Live Chat Support",
                          "Claim Assistance",
                          "Application Fee Information",
                          "Scholarship and Financial Aid Information",
                          "Multilingual Support",
                          "Application Tracking System",
                        ].map((item, index) => (
                          <li key={index}>{t(`directServicePage.${item}`)}</li>
                        ))}
                      </ul>
                    </div>
                  </Form.Group>
                  <Button
                    type="submit"
                    className="w-full text-white bg-blue-500 hover:bg-blue-600 mt-4 py-2 rounded-md"
                  >
                    <span className="text-xl">
                      {t("directServicePage.Submit")}
                    </span>
                  </Button>
                </div>
              </>
            )}
            {error && (
              <Alert variant="danger" className="col-span-2 mt-3">
                {error}
              </Alert>
            )}
          </Form>
        </Container>
      </Container>
    </div>
  );
}
