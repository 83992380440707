import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowLeft,
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { serviceHomeDetails } from "../../../DataFiles/data";
import LazyLoad from "react-lazyload";
import { FiMapPin } from "react-icons/fi";
import VerifiedStatus from "../../../components/common/VerifiedStatus";

export default function Head({ t, item, userData }) {
  const navigate = useNavigate();

  const handleNext = (increment) => {
    const getIndex = serviceHomeDetails.findIndex((e) => e.title === item.name);
    const newIndex =
      (getIndex + increment + serviceHomeDetails.length) %
      serviceHomeDetails.length;
    navigate(
      `/inDirectServicePage/${serviceHomeDetails[newIndex].title}?status=pending`
    );
  };

  return (
    <div className="head1">
      <div className="dethead1">
        <div>
          <Link aria-label="Home" to="/">
            {t("inDirectServicePage.Home")}
          </Link>
          <span className="mx-1 text-[#4475b4]">/</span>
          {t(`inDirectServicePage.${item.name}`)}
        </div>
        <div>
          <h2>{t(`inDirectServicePage.${item.name}`)}</h2>
        </div>
        <div>
          <span className="flex items-center">
            <HiOutlineArrowNarrowLeft
              className="mr-2 rounded shadow-md hover:scale-105"
              onClick={() => handleNext(-1)}
            />
            {t("inDirectServicePage.Next Service")}
            <HiOutlineArrowNarrowRight
              className="ml-2 rounded shadow-md hover:scale-105"
              onClick={() => handleNext(1)}
            />
          </span>
        </div>
      </div>
      <div className="flex justify-center">
        <Row className="dethead2">
          <Col className="dethead2sub1">
            <div className="flex flex-nowrap mb-3">
              <img
                className="w-[35px] h-[35px]"
                src={userData?.profileImage}
                alt="User"
              />
              <section>
                <div className="flex items-center text-nowrap">
                  <h5 className="font-semibold ml-2 ">{userData?.name}</h5>
                  <VerifiedStatus isVerified={userData?.isVerified} />
                </div>
              </section>
            </div>

            <div className="flex flex-column">
              <h6 className="flex items-center text-[15px] mt-3">
                <FiMapPin className="mr-2 text-[#4475b4]" />
                {userData?.preferredState}, Germany
              </h6>
              <div>
                {[item.smallicon1, item.smallicon2, item.smallicon3].map(
                  (icon, index) => (
                    <LazyLoad key={index} height={200} offset={100} once>
                      <img
                        className="dethead2img1 mx-2"
                        src={icon}
                        alt={`${item.name} icon${index + 1}`}
                      />
                    </LazyLoad>
                  )
                )}
              </div>
            </div>
          </Col>
          <Col md={6} className="dethead2sec2">
            <LazyLoad height={200} offset={100} once>
              <img src={item.headImage} alt={`${item.name} Icon`} />
            </LazyLoad>
          </Col>
          <Col className="dethead2sub2">
            <h5 className="font-semibold text-nowrap">
              {t(`inDirectServicePage.${item.name}`)} {t(`request`)}
            </h5>
            <p className="max-w-[350px] text-center">
              {item.name === "Room"
                ? t(`inDirectServicePage.Room description`)
                : t(`inDirectServicePage.Airport assistance description`)}
              <a
                href="#service_description"
                className="text-[#4475b4] cursor-pointer ml-2"
              >
                {t("inDirectServicePage.know more")}
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
