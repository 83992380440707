import { Button, Container } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import CSS for react-confirm-alert
import { PiWarningOctagonBold } from "react-icons/pi";

export default function Status({ t, item, handleRemove, error }) {
  const submit = (title, message, label1, label2, clickHandler) => {
    confirmAlert({
      title,
      message,
      buttons: [
        {
          label: label1,
          onClick: clickHandler,
        },
        {
          label: label2,
        },
      ],
    });
  };

  return (
    <Container fluid className="shadow rounded my-20 py-5 bg-warning">
      <div className="parentStatus">
        <img
          className="statusImg"
          src="/images/directPage/status.webp"
          alt="statusDService"
        />
        <div className="statusDiv ml-5">
          {" "}
          {/* Adjusted spacing */}
          <h3 className="font-bold text-danger">
            {t("directServicePage.Services Successfully Completed")}
          </h3>
          <h5 className="font-bold mb-3">
            {t("directServicePage.Congratulations")}! 🎉
          </h5>{" "}
          {/* Added margin bottom */}
          <div className="text-gray-600 max-w-[1200px]">
            <p>
              {t(
                "directServicePage.You have successfully completed the necessary"
              )}
            </p>
            <p className="mb-3">
              {" "}
              {/* Added margin bottom */}
              {t("directServicePage.Thank you for choosing our service")}
            </p>
          </div>
          <Button
            onClick={() => {
              submit(
                t("directServicePage.Confirm to Delete"),
                `${t("directServicePage.Are you sure to delete the")} ${item}.`,
                t("directServicePage.Yes"),
                t("directServicePage.No"),
                handleRemove
              );
            }}
            className="border-0 px-5 py-2 text-white rounded hover:shadow-lg"
            style={{ fontSize: "20px", backgroundColor: "rgb(133, 9, 9)" }}
          >
            {t("directServicePage.Start Over")}
          </Button>
          {error && (
            <div className="col-span-2 text-danger my-2" id="error">
              <div className="flex justify-center text-danger">
                <PiWarningOctagonBold className="mr-2 text-[25px]" />
                {error}
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
