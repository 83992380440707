import React, { useState, useEffect } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { HiMenuAlt1 } from "react-icons/hi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineMenuFold } from "react-icons/ai";
import { VscAccount } from "react-icons/vsc";
import { Link } from "react-router-dom";
import MessageNotification from "./MessageNotification";
import ServiceNotification from "./ServiceNotification";
import Language from "./Language";
import { serviceHomeDetails } from "../../../DataFiles/data";
import "../../components.scss";
import { FaHandHoldingUsd } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { BsPersonFillExclamation } from "react-icons/bs";
import { GrArticle } from "react-icons/gr";
import { GiNewspaper } from "react-icons/gi";
import { FaLaptopHouse, FaQuestionCircle } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { CiYoutube } from "react-icons/ci";

const NavItem = React.memo(({ icon: Icon, to, label, navigate, t }) => (
  <div
    onClick={() => navigate(to)}
    className="cursor-pointer flex items-center text-normal pt-1 mb-3"
  >
    <Icon className="mr-2 text-[22px]" />
    <span className="">{t(`navigation.${label}`)}</span>
  </div>
));

const ServiceLinks = ({ service, navigate, t }) => {
  const links = [
    {
      to: `/inDirectServicePage/${service.title}?status=form`,
      icon: IoCreateOutline,
      label: "My request",
    },
    {
      to: `/myProfile/find_work?service=${service.title}&pageNumber=1`,
      icon: FaHandHoldingUsd,
      label: "Others request",
    },
    {
      to: `/myProfile/workStatus?service=${service.title}&work_status=pending`,
      icon: () => (
        <img
          src="/images/icons/pending.webp"
          alt=""
          className="mr-2 w-[18px]"
        />
      ),
      label: "Pending response",
    },
    {
      to: `/myProfile/workStatus?service=${service.title}&work_status=accepted`,
      icon: () => (
        <img
          src="/images/icons/accepted.webp"
          alt=""
          className="mr-2 w-[18px]"
        />
      ),
      label: "Accepted response",
    },
  ];

  return (
    <div className="ml-5 border-l border-grey pl-3 py-1 my-0">
      {links.map((link, index) => (
        <NavItem key={index} {...link} navigate={navigate} t={t} />
      ))}
    </div>
  );
};

export default function SmallScreenNav({ t, navigate, dispatch }) {
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState("Room");

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
    return () => (document.body.style.overflow = "auto");
  }, [show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const socialLinks = [
    { to: "/FAQs-Stayforall", icon: FaWhatsapp },
    { to: "/FAQs-Stayforall", icon: CiFacebook },
    { to: "/FAQs-Stayforall", icon: FaInstagram },
    { to: "/FAQs-Stayforall", icon: CiYoutube },
  ];

  const otherLinks = [
    {
      to: "/about-us-Stayforall",
      icon: BsPersonFillExclamation,
      label: "About us",
    },
    {
      to: "/blogs&article-Stayforall?page=1",
      icon: GrArticle,
      label: "Blog & Article",
    },
    {
      to: "/news-Stayforall?page=1",
      icon: GiNewspaper,
      label: "News",
    },
    {
      to: "/webinars-Stayforall",
      icon: FaLaptopHouse,
      label: "Webinars",
    },
    { to: "/FAQs-Stayforall", icon: FaQuestionCircle, label: "FAQs" },
  ];

  const servicesLinks = [
    {
      to: "/Room verification",
      icon: () => (
        <img
          src={`/images/icons/HealthInsurance.webp`}
          alt="Status Icon"
          style={{ width: "22px", marginRight: "5px" }}
        />
      ),
      label: "Room verification",
    },
    {
      to: "/directServicePage/Admission%20assistance",
      icon: () => (
        <img
          src={`/images/icons/PaperworkAssistance.webp`}
          alt="Status Icon"
          style={{ width: "25px", marginRight: "5px" }}
        />
      ),
      label: "Admission assistance",
    },
  ];

  return (
    <>
      <div className="flex items-center">
        <Nav.Item>
          <HiMenuAlt1
            onClick={handleShow}
            className="cursor-pointer text-lightBlack text-[22px] ml-2 mb-1"
          />
        </Nav.Item>
        <Nav.Item>
          <div onClick={() => navigate(`/`)} className="cursor-pointer">
            <img
              src="/images/logo/SFAmediumLogo.webp"
              className="ml-2 w-[70px]"
              alt="SFAmediumLogo"
            />
          </div>
        </Nav.Item>
      </div>

      <div className="flex items-center">
        <Language size="small" />
        <div className="flex justify-between items-center">
          <MessageNotification dispatch={dispatch} t={t} navigate={navigate} />
          <ServiceNotification dispatch={dispatch} t={t} navigate={navigate} />
          <Link
            to="/myProfile/profile"
            className="cursor-pointer text-[24px] m-[12px]"
          >
            <VscAccount className="text-lightBlack" />
          </Link>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} style={{ width: "300px" }}>
        <Offcanvas.Header className="flex items-center justify-between">
          <img
            src="/images/logo/SFAlargeLogo.webp"
            className="ml-2 h-[30px]"
            alt="SFAlargeLogo"
          />
          <AiOutlineMenuFold onClick={handleClose} className="cursor-pointer" />
        </Offcanvas.Header>
        <Offcanvas.Body className="space-y-4 border-top">
          <h5 className="text-[#0D98BA] underline ">
            {t("navigation.Help others with")}
          </h5>
          {serviceHomeDetails.map((service, index) => (
            <div key={index}>
              <div
                onClick={() => {
                  category == service.title
                    ? setCategory("")
                    : setCategory(service.title);
                }}
                className="cursor-pointer d-flex justify-between items-center hover:animate-pulse"
              >
                <div className="flex items-center text-normal pt-1 ">
                  <img
                    src={service.icon}
                    alt="InDirectService Icon"
                    style={{ width: "25px" }}
                  />
                  <span className="ml-2">
                    {t(`navigation.${service.title}`)}
                  </span>
                </div>
                {category === service.title ? (
                  <MdKeyboardArrowDown className="text-xl righthide" />
                ) : (
                  <MdKeyboardArrowRight className="text-xl righthide" />
                )}
              </div>
              {category === service.title && (
                <ServiceLinks service={service} navigate={navigate} t={t} />
              )}
            </div>
          ))}
          <h5 className="text-[#0D98BA] underline  mt-5">
            {t("navigation.Services")}
          </h5>
          {servicesLinks.map((item, index) => (
            <NavItem key={index} {...item} navigate={navigate} t={t} />
          ))}
          <h5 className="text-[#0D98BA] underline  mt-5">
            {t("navigation.Others")}
          </h5>
          <div className="mb-5">
            {otherLinks.map((item, index) => (
              <NavItem key={index} {...item} navigate={navigate} t={t} />
            ))}
          </div>
          <div className="fixed bottom-0 bg-[#000000c9] rounded-t mt-5 py-2 px-2 flex">
            {socialLinks.map((platform, index) => (
              <div
                key={index}
                onClick={() => navigate(platform.to)}
                className="mx-3 flex flex-column items-center justify-center text-white rounded"
              >
                <platform.icon className="mr-2 text-[22px]" />
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
