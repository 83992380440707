export const directCategories = [
  {
    name: "Room verification",
    smallDescription: "Room verification Small Description",
    image: "/images/homepage/healthInsurance.webp",
    icon: "/images/icons/HealthInsurance.webp",
    contsection: "/images/directPage/healthInsurance.webp",
    form: [
      { label: "", type: "", placeholder: "", select: [], required: true },
    ],
  },

  {
    name: "Admission assistance",
    smallDescription: "Admission Assistance Small Description",
    image: "/images/homepage/paperwork.webp",
    icon: "/images/icons/PaperworkAssistance.webp",
    contsection: "/images/directPage/paperwork.webp",
    form: [
      { label: "", type: "", placeholder: "", select: [], required: true },
    ],
  },
];
export const inDirectCategories = {
  Room: {
    name: "Room",
    smallDescription: "Find your perfect room by specifying your preferences",
    headImage: "/images/inDirect/rooms.webp",
    smallicon1: "/images/inDirect/room1.webp",
    smallicon2: "/images/inDirect/room2.webp",
    smallicon3: "/images/inDirect/room3.webp",
    bubble: "/images/inDirect/roomBubble.webp",
  },
  Airportassistance: {
    name: "Airport assistance",
    smallDescription: "Arrange smooth airport pickups and transportation",
    headImage: "/images/inDirect/airport.webp",
    smallicon1: "/images/inDirect/airport1.webp",
    smallicon2: "/images/inDirect/airport2.webp",
    smallicon3: "/images/inDirect/airport3.webp",
    bubble: "/images/inDirect/airportBubble.webp",
  },
};
export const roomListingData = [
  {
    type: "Apartment",
    description:
      "A modern apartment located in a convenient urban area, ideal for city living.",
  },
  {
    type: "Independent House",
    description:
      "A spacious independent house with a private garden, perfect for families.",
  },
  {
    type: "Independent Floor",
    description:
      "An independent floor offering privacy and exclusive amenities.",
  },
  {
    type: "Studio",
    description: "A compact studio apartment, perfect for singles or students.",
  },
  {
    type: "Duplex",
    description: "A luxurious duplex with modern fittings and ample space.",
  },
  {
    type: "Penthouse",
    description:
      "An exclusive penthouse with a panoramic view of the city skyline.",
  },
  {
    type: "Villa",
    description:
      "A grand villa with extensive grounds and top-notch facilities.",
  },
];
export const serviceHomeDetails = [
  {
    title: "Room",
    image: "/images/homepage/room1.webp",
    avgRating: 5.0,
    avgPrice: 150,
    smallDescription: "Find your perfect room by specifying your preferences.",
    detailedDescription:
      "Find the perfect room that fits your budget and preferences. Our platform allows you to create requests for accommodation, specifying your location, budget, and move-in date preferences. Room providers will express interest, and you can select the best match to finalize your arrangements.",
    icon: "/images/icons/Rooms.webp",
  },
  {
    title: "Airport assistance",
    image: "/images/homepage/airport1.webp",
    avgRating: 5.0,
    avgPrice: 100,
    smallDescription: "Arrange smooth airport pickups and transportation.",
    detailedDescription:
      "Get help with airport pickups and transportation to your accommodation. Create a request detailing your needs, such as pickup time and destination. Service providers will express interest, and you can choose the most suitable option to ensure a smooth journey from the airport.",
    icon: "/images/icons/AirportAssistance.webp",
  },
];

export const searchDetails = [
  { caption: "Room", link: "/inDirectServicePage/Room?status=pending" },
  {
    caption: "Airport Assistance",
    link: "/inDirectServicePage/Airport Assistance?status=pending",
  },
  {
    caption: "Find Skill",
    link: "/inDirectServicePage/Find Skill?status=pending",
  },
  {
    caption: "Join A Group",
    link: "/inDirectServicePage/Join A Group?status=pending",
  },
  { caption: "HP Footer About us", link: "/about-us-Stayforall" },
  { caption: "HP searchResult Careers", link: "/career-Stayforall" },
  { caption: "HP Footer News", link: "/news-Stayforall?page=1" },
  { caption: "HP Blog & Article", link: "/blogs&article-Stayforall?page=1" },
  { caption: "HP Footer FAQs", link: "/FAQs-Stayforall" },
  { caption: "HP Footer Webinars", link: "/webinars-Stayforall" },
  { caption: "HP searchResult Profile", link: "/myProfile/profile" },
];
export const faqs = [
  {
    question: "faq1",
    answer: "ans1",
  },
  {
    question: "faq2",
    answer: "ans2",
  },
  {
    question: "faq3",
    answer: "ans3",
  },
  {
    question: "faq4",
    answer: "ans4",
  },
  {
    question: "faq5",
    answer: "ans5",
  },
  {
    question: "faq6",
    answer: "ans6",
  },
  {
    question: "faq7",
    answer: "ans7",
  },
  {
    question: "faq8",
    answer: "ans8",
  },
  {
    question: "faq9",
    answer: "ans9",
  },
  {
    question: "faq10",
    answer: "ans10",
  },
];
export const roomStaticData = {
  propertyType: [
    "Apartment",
    "Independent House",
    "Independent Floor",
    "Studio",
    "Duplex",
    "Penthouse",
    "Villa",
  ],
  roomType: ["SemiFurnished", "UnFurnished", "Furnished"],
  number_of_Bedrooms: [
    "1 RK/1 BHK",
    "2 BHK",
    "3 BHK",
    "4 BHK",
    "5 BHK",
    "6 BHK",
    "7 BHK",
    "8 BHK",
    "9 BHK",
    "9+ BHK",
  ],
  lengthOfLease: [
    "month -to-month",
    "less than six months",
    "less than one year",
    "more than one year",
  ],
};
