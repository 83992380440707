import { createReducer } from "@reduxjs/toolkit";

export const DashboardReducer = createReducer(
  {
    loading: false,
    data: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("getDashboardRequest", (state) => {
        state.loading = true;
        state.data = null;
        state.error = null;
      })
      .addCase("getDashboardSuccess", (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase("getDashboardFailure", (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })

      .addCase("cleardata", (state) => {
        state.data = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
