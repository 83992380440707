import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OthersInDService,
  createInterest,
  updateNotInterested,
} from "../../../store/Actions/InDirectService";
import "./FindWork.scss";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { germanStates } from "../../../DataFiles/countries";
import Filtering from "./components/Filtering";
import SelectService from "./components/SelectService";
import Room from "./services/Room";
import AirportAssistance from "./services/AirportAssistance";
import { PiWarningOctagonBold } from "react-icons/pi";
import Loading from "../../../components/samplePage/Loading";
import { AiOutlineHome } from "react-icons/ai";
import { toast } from "react-toastify";
import { logoutUser } from "../../../store/Actions/User";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { useEffectFindWork } from "../hooks/useEffectFindWork";
import { Col, Row, Form, Button, Alert } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { FaInfoCircle, FaAngleDoubleDown } from "react-icons/fa";

export default function FindWork({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let query = new URLSearchParams(location.search);

  const user = useSelector((state) => state.user);
  const inDService = useSelector((state) => state.inDService);
  const othersInDService = useSelector((state) => state.othersInDService);
  const interest = useSelector((state) => state.interest);
  const userInfo = useSelector((state) => state.userInfo);

  // useState for room sorting
  const [pageNumber, setPageNumber] = useState(query.get("pageNumber") || 1);
  const [state, setState] = useState(query.get("state") || "");
  const [city, setCity] = useState(query.get("city") || "");
  const [uploadDate, setUploadDate] = useState(query.get("uploadDate") || "-1");
  const [moveInDate, setMoveInDate] = useState(query.get("moveInDate") || "");
  const [propertyType, setPropertyType] = useState(
    query.get("propertyType") || ""
  );
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(
    query.get("numberOfBedrooms") || ""
  );
  const [lengthOfLease, setLengthOfLease] = useState(
    query.get("lengthOfLease") || ""
  );
  const [roomType, setRoomType] = useState(query.get("roomType") || "");
  const [utilities, setUtilities] = useState(query.get("utilities") || "");
  const [rent, setRent] = useState(query.get("rent") || "");
  const [securityDeposit, setSecurityDeposit] = useState(
    query.get("securityDeposit") || ""
  );
  const [serviceFee, setServiceFee] = useState(query.get("serviceFee") || "");
  const [services, setServices] = useState([]);

  // useState for airport assistance sorting
  const [searchPayment, setSearchPayment] = useState(
    query.get("payment") || ""
  );
  const [airportName, setAirportName] = useState(
    query.get("airportName") || ""
  );
  const [arrivalDate, setArrivalDate] = useState(
    query.get("arrivalDate") || ""
  );
  const [payment, setPayment] = useState(query.get("payment") || "");

  // useState for works
  const [serviceId, setServiceId] = useState("");
  const [viewService, setViewService] = useState("");
  const [error, setError] = useState("");
  const [interestError, setInterestError] = useState("");

  // useState for sorting
  const [showSort, setShowSort] = useState(false);

  const [readMore, setReadMore] = useState(false);
  const [searchRent, setSearchRent] = useState(query.get("rent") || "");
  const [searchSecurityDeposit, setSearchSecurityDeposit] = useState(
    query.get("securityDeposit") || ""
  );

  const service = query.get("service");
  const category = service.replaceAll(" ", "");

  const stateData = germanStates.find((e) => e.sampleName === state);

  const airportOptions = stateData ? stateData.airport : [];

  // functions
  const logoutHandler = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleUpdatePage = (e) => {
    setPageNumber(e);
    query.set("pageNumber", e);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  const handleIds = (id) => {
    setServiceId(id);
    setInterestError("");
  };

  const handleView = (value) => {
    return setViewService(value);
  };

  const handleHide = async (serviceIdSelect) => {
    dispatch(updateNotInterested(service, serviceIdSelect)).then(() => {
      if (error) {
        return toast.error(error);
      } else {
        toast.warn(t("findWork.Service is hidden"));
        const updatedServices = services.filter(
          (e) => e.id !== serviceIdSelect
        );
        setServices(updatedServices);
      }
    });
  };

  const handleSubmit = (file) => {
    dispatch(createInterest(service, serviceId, file)).then(() => {
      if (interestError) {
        return toast.error(interestError);
      } else {
        toast.success(t("findWork.Interest created successfully"));
        const updatedServices = services.filter((e) => e.id !== serviceId);
        setServices(updatedServices);
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (service == "Room") {
      setRent(searchRent);
      setSecurityDeposit(securityDeposit);
      query.set("rent", searchRent);
      query.set("securityDeposit", searchSecurityDeposit);
    } else {
      setPayment(searchPayment);
      query.set("payment", searchPayment);
    }

    navigate({ search: query.toString() }, { replace: true });
  };

  // useEffect
  useEffectFindWork({
    user,
    dispatch,
    OthersInDService,
    service,
    state,
    city,
    uploadDate,
    moveInDate,
    rent,
    securityDeposit,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
    airportName,
    arrivalDate,
    payment,
    pageNumber,
    handleView,
    setServices,
    othersInDService,
    setState,
    userInfo,
    interest,
    setError,
    inDService,
    setInterestError,
    handleIds,
    query,
    navigate,
    location,
  });

  return (
    <>
      {user.loading ? (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      ) : (
        <>
          {/*overView head*/}
          <div className="overveiw_cont findWorkCont">
            <div className="p-3">
              <div className="flex justify-between">
                <h2
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                  className="flex items-center cursor-pointer flex-wrap text-[#0cafff] mb-3 mt-1"
                >
                  <FaInfoCircle className="mr-2" />{" "}
                  <span className="font-bold mr-2">{t("findWork.Offer")}</span>{" "}
                  {t(`findWork.${service}`)} {t("findWork.services")}
                  {!readMore && (
                    <span className="flex items-center animate-bounce text-[black] text-[16px] mt-2">
                      <FaAngleDoubleDown className="ml-2" />
                    </span>
                  )}
                </h2>
                <a href="/" className="homeAnchor">
                  <AiOutlineHome className="text-[2.1rem] rounded-circle bg-grey text-white p-2 hover:scale-105" />
                </a>
              </div>

              <p className="text-secondary">
                {t("findWork.Here, you can browse a variety of")} {service}{" "}
                {t("findWork.requests created by fellow community members")}
                {readMore && (
                  <>
                    <br />
                    {t("findWork.Respond to these service requests to")}
                  </>
                )}
              </p>
            </div>

            <div className="bg-white">
              {/* services listed */}
              <SelectService
                t={t}
                service={service}
                navigate={navigate}
                selectClass={false}
              />
              {/* sort options */}
              <div className="findSmall bg-[#f7f7f9] flex items-center justify-end">
                <button
                  onClick={() => setShowSort(true)}
                  // style={{ border: "1px solid rgb(148, 147, 147, .2)" }}
                  className="shadow-sm bg-white border px-2 flex items-center mx-2 text-[19px]"
                >
                  <FaFilterCircleDollar className="mr-2" />
                  {t("findWork.Filter")}
                </button>
              </div>
              <Filtering
                t={t}
                germanStates={germanStates}
                service={service}
                stateOptions={germanStates}
                state={state}
                setState={setState}
                // Room
                city={city}
                setCity={setCity}
                uploadDate={uploadDate}
                setUploadDate={setUploadDate}
                moveInDate={moveInDate}
                setMoveInDate={setMoveInDate}
                rent={rent}
                setRent={setRent}
                securityDeposit={securityDeposit}
                setSecurityDeposit={setSecurityDeposit}
                serviceFee={serviceFee}
                setServiceFee={setServiceFee}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                numberOfBedrooms={numberOfBedrooms}
                setNumberOfBedrooms={setNumberOfBedrooms}
                lengthOfLease={lengthOfLease}
                setLengthOfLease={setLengthOfLease}
                roomType={roomType}
                setRoomType={setRoomType}
                utilities={utilities}
                setUtilities={setUtilities}
                // Airport assistance
                airportOptions={airportOptions}
                airportName={airportName}
                setAirportName={setAirportName}
                arrivalDate={arrivalDate}
                setArrivalDate={setArrivalDate}
                showSort={showSort}
                setShowSort={setShowSort}
              />
            </div>
            <Form
              className="flex justify-center items-center"
              onSubmit={handleSearch}
            >
              <div className="flex justify-center items-center rounded-[10px] pt-1 pb-2 px-3 m-2 bg-white">
                {service == "Room" ? (
                  <>
                    {" "}
                    <Form.Group className="border-b border-grey">
                      <Form.Control
                        type="number"
                        placeholder={t("findWork.Rent")}
                        value={searchRent}
                        onChange={(e) => setSearchRent(e.target.value)}
                        className="px-4 py-2 border-0 shadow-none"
                      />
                    </Form.Group>
                    <Form.Group className="border-b border-grey mx-3">
                      <Form.Control
                        type="number"
                        placeholder={t("findWork.Security Deposit")}
                        value={searchSecurityDeposit}
                        onChange={(e) =>
                          setSearchSecurityDeposit(e.target.value)
                        }
                        className="px-4 py-2 border-0 shadow-none"
                      />
                    </Form.Group>
                  </>
                ) : service == "Airport assistance" ? (
                  <Form.Group className="border-b border-grey md:w-[360px] mr-3">
                    <Form.Control
                      type="number"
                      placeholder={t("findWork.Payment")}
                      value={searchPayment}
                      onChange={(e) => setSearchPayment(e.target.value)}
                      className="px-4 py-2 border-0 shadow-none"
                    />
                  </Form.Group>
                ) : (
                  ""
                )}
                <Button
                  variant=""
                  type="submit"
                  style={{ background: "#0cafff" }}
                  className="text-white p-2 rounded-circle transition duration-200 shadow hover:scale-[1.05]"
                >
                  <FaSearch />
                </Button>
              </div>
            </Form>
            <Row className="pl-2 pr-5">
              {/*overveiw body*/}
              <Col md={8} lg={9} className="findWorkBody ">
                {interest.loading && (
                  <Loading type="hoverLoading" color="#36d7b7" size={120} />
                )}
                {/* error */}
                {error && (
                  <Alert variant="danger" className="col-span-2 mt-3">
                    {error}
                  </Alert>
                )}
                {/*content start*/}
                {othersInDService.loading ? (
                  <div className="heightFindWork">
                    <Loading type="colOne" color="#36d7b7" size={20} />
                  </div>
                ) : othersInDService.services?.length == 0 ? (
                  <div className="text-center pt-[20px] pb-[60vh]">
                    <h6 className="flex flex-col font-[Helvetica] items-center rounded text-secondary bg-white shadow-md p-5">
                      <PiWarningOctagonBold className="mb-2 text-[50px]" />
                      {t("findWork.No")} {t(service).toLowerCase()}{" "}
                      {t("findWork.requests available. Check back soon")}
                    </h6>
                  </div>
                ) : (
                  <div className="min-h-[80vh] flex justify-center w-[100%]">
                    {service == "Room" ? (
                      <Room
                        t={t}
                        services={services}
                        viewService={viewService}
                        serviceId={serviceId}
                        handleView={handleView}
                        handleHide={handleHide}
                        handleIds={handleIds}
                        handleSubmit={handleSubmit}
                        interestError={interestError}
                      />
                    ) : service == "Airport assistance" ? (
                      <AirportAssistance
                        t={t}
                        services={services}
                        viewService={viewService}
                        serviceId={serviceId}
                        handleView={handleView}
                        handleHide={handleHide}
                        handleIds={handleIds}
                        handleSubmit={handleSubmit}
                        interestError={interestError}
                      />
                    ) : (
                      <h3 className="text-danger">
                        {t("findWork.Select Valid Category")}
                      </h3>
                    )}
                  </div>
                )}
                {/*content end*/}
              </Col>

              <Col className="findLarge border bg-white shadow-md rounded px-2 py-3 my-3">
                <h3 className="font-bold text-[19px]">
                  {t("findWork.Filter")} {t("findWork.services")}
                </h3>
                <p className="text-secondary text-[15px]">
                  {t("findWork.showing")}{" "}
                  {pageNumber == 1
                    ? `1-12`
                    : `${1 * pageNumber}-${12 * pageNumber}`}{" "}
                  {t("findWork.services")}
                </p>

                <Filtering
                  t={t}
                  germanStates={germanStates}
                  service={service}
                  stateOptions={germanStates}
                  state={state}
                  setState={setState}
                  // Room
                  city={city}
                  setCity={setCity}
                  uploadDate={uploadDate}
                  setUploadDate={setUploadDate}
                  moveInDate={moveInDate}
                  setMoveInDate={setMoveInDate}
                  rent={rent}
                  setRent={setRent}
                  securityDeposit={securityDeposit}
                  setSecurityDeposit={setSecurityDeposit}
                  serviceFee={serviceFee}
                  setServiceFee={setServiceFee}
                  propertyType={propertyType}
                  setPropertyType={setPropertyType}
                  numberOfBedrooms={numberOfBedrooms}
                  setNumberOfBedrooms={setNumberOfBedrooms}
                  lengthOfLease={lengthOfLease}
                  setLengthOfLease={setLengthOfLease}
                  roomType={roomType}
                  setRoomType={setRoomType}
                  utilities={utilities}
                  setUtilities={setUtilities}
                  // Airport assistance
                  airportOptions={airportOptions}
                  airportName={airportName}
                  setAirportName={setAirportName}
                  arrivalDate={arrivalDate}
                  setArrivalDate={setArrivalDate}
                  showSort={showSort}
                  setShowSort={setShowSort}
                  forLarge={true}
                />
              </Col>
            </Row>
            {/*end section*/}
            <div className="pageNavigate">
              {pageNumber != "1" && (
                <button
                  onClick={() => handleUpdatePage(parseInt(pageNumber) - 1)}
                  className="cursor-pointer rounded-[25px] bg-white text-secondary px-2 py-1 shadow-md flex items-center"
                >
                  <GrLinkPrevious className="mr-2" />
                  <span className="font-bold">{t("findWork.Previous")}</span>
                </button>
              )}
              <div className="text-[14px] text-grey mx-5">
                {t("findWork.Page")} {pageNumber}
              </div>
              {services?.length > 21 && (
                <button
                  onClick={() => handleUpdatePage(parseInt(pageNumber) + 1)}
                  className="cursor-pointer rounded-[25px] bg-white text-secondary px-2 py-1 shadow-md flex items-center"
                >
                  <span className="font-bold">{t("findWork.Next")}</span>
                  <GrLinkNext className="ml-2" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
