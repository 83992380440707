import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { germanStates } from "../../../DataFiles/countries";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { AiOutlineArrowRight, AiOutlineArrowDown } from "react-icons/ai";

const Header = ({ t }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    selectService: "Room",
    state: "Berlin",
    rent: "",
    securityDeposit: "",
    airport: "",
    serviceFee: "",
  });

  const scrollToComponent = (id) => {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleServiceChange = (service) => {
    setFormData((prevData) => ({
      ...prevData,
      selectService: service,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (formData.selectService === "Room") {
      navigate(
        `/myProfile/find_work?service=Room&pageNumber=1&state=${formData.state}&rent=${formData.rent}&securityDeposit=${formData.securityDeposit}`
      );
    } else
      navigate(
        `/myProfile/find_work?service=Airport+assistance&pageNumber=1&state=${formData.state}&uploadDate=-1&airportName=${formData.airport}&payment=${formData.serviceFee}`
      );
  };

  const airports =
    germanStates.find((e) => e.sampleName === formData.state)?.airport || [];

  return (
    <div className="backgroundsethome">
      <Container className="headerHead">
        <h1 className="">
          <span className="typewriter"></span>
          <br />
          {t(`homePage.Help others to find`)}
        </h1>
        <div className="pt-6">
          <div className="mb-2">
            {["Room", "Airport assistance"].map((service) => (
              <button
                key={service}
                className={`rounded mr-4 px-3 py-1 font-bold border border-gray-500 ${
                  formData.selectService === service
                    ? "bg-[#3f3f3f] text-white"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => handleServiceChange(service)}
              >
                {t(`homePage.${service}`)}
              </button>
            ))}
          </div>
          <Form onSubmit={handleSearch} className="homeFormSection shadow-lg">
            <Form.Group className="border-r border-gray-500">
              <Form.Select
                className="border-0 shadow-none"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              >
                {germanStates.map((e) => (
                  <option key={e.sampleName} value={e.sampleName}>
                    {e.sampleName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {formData.selectService === "Room" && (
              <>
                {["rent", "securityDeposit"].map((field) => (
                  <Form.Group
                    key={field}
                    className="border-b border-gray-500 mx-2"
                  >
                    <Form.Control
                      className="border-0 shadow-none"
                      type="text"
                      placeholder={t(`homePage.${field}`)
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                ))}
              </>
            )}
            {formData.selectService === "Airport assistance" && (
              <>
                <Form.Group className="border-b border-gray-500 mx-1">
                  <Form.Select
                    className="border-0 shadow-none"
                    name="airport"
                    value={formData.airport}
                    onChange={handleInputChange}
                  >
                    <option hidden value="">
                      {t(`homePage.select airport`)}
                    </option>
                    {airports.map((e) => (
                      <option key={e.name} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="border-b border-gray-500">
                  <Form.Control
                    className="border-0 shadow-none"
                    type="text"
                    placeholder={t(`homePage.service fee`)}
                    name="serviceFee"
                    value={formData.serviceFee}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}
            <button
              className="d-flex items-center px-2 py-1 text-[18px] border rounded text-white bg-[#24A4DF] hover:shadow-md"
              type="submit"
            >
              <CiSearch />
              <span className="findMatchBtn ml-1">{t(`homePage.search`)}</span>
            </button>
          </Form>
        </div>
      </Container>
      <Container className="flex justify-end">
        <div className="homeimgmain border">
          <img
            className="d-block w-100"
            src={`/images/homepage/header-1.webp`}
            alt=""
          />
        </div>
      </Container>
    </div>
  );
};

export default React.memo(Header);
