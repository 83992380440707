import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const registerUser = (file) => async (dispatch) => {
  try {
    dispatch({
      type: "registerRequest",
    });
    await axios.post(`${apiUrl}/register`, file, {
      withCredentials: true,
    });

    dispatch({
      type: "registerSuccess",
    });
  } catch (error) {
    dispatch({
      type: "registerFailure",
      payload: error.response.data.message,
    });
  }
};

export const loginUser = (file) => async (dispatch) => {
  try {
    dispatch({
      type: "loginRequest",
    });

    await axios.post(`${apiUrl}/login`, file, {
      withCredentials: true,
    });

    dispatch({
      type: "loginSuccess",
    });

    const { data } = await axios.get(`${apiUrl}/load`, {
      withCredentials: true,
    });

    dispatch({
      type: "loadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "loginFailure",
      payload: error.response.data.message,
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "logoutRequest",
    });

    const { data } = await axios.get(`${apiUrl}/logout`, {
      withCredentials: true,
    });

    dispatch({
      type: "logoutSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "logoutFailure",
      payload: error.response.data.message,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "loginRequest",
    });
    dispatch({ type: "loadUserRequest" });

    const { data } = await axios.get(`${apiUrl}/load`, {
      withCredentials: true,
    });

    dispatch({
      type: "loginSuccess",
    });
    dispatch({
      type: "loadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "loadUserFailure",
      payload: error.response.data.message,
    });
    dispatch({
      type: "loginFailure",
    });
  }
};

export const updateUser = (profileInfo) => async (dispatch) => {
  try {
    dispatch({ type: "updateProfileRequest" });

    const { data } = await axios.put(`${apiUrl}/update`, profileInfo, {
      withCredentials: true,
    });

    dispatch({
      type: "updateProfileSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updateProfileFailure",
      payload: error.response.data.message,
    });
  }
};

export const changePasswordMailSend = (email) => async (dispatch) => {
  try {
    dispatch({
      type: "changePasswordMailSendRequest",
    });

    const { data } = await axios.post(
      `${apiUrl}/forget_password_mail`,
      { email },
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "changePasswordMailSendSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "changePasswordMailSendFailure",
      payload: error.response.data.message,
    });
  }
};

export const forgotPasswordChange = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: "updatePasswordRequest",
    });

    const { data } = await axios.post(
      `${apiUrl}/forgot_password_change/${token}`,
      { password: password },
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "updatePasswordSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updatePasswordFailure",
      payload: error.response.data.message,
    });
  }
};

export const passwordChange = (password) => async (dispatch) => {
  try {
    dispatch({
      type: "updatePasswordRequest",
    });

    const { data } = await axios.post(
      `${apiUrl}/password_change`,
      { password: password },
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "updatePasswordSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "updatePasswordFailure",
      payload: error.response.data.message,
    });
  }
};

export const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: "getUserRequest" });

    const { data } = await axios.get(`${apiUrl}/user`, {
      withCredentials: true,
    });
    dispatch({
      type: "getUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "getUserFailure",
      payload: error.response.data.message,
    });
  }
};

export const uploadDocument = (file) => async (dispatch) => {
  try {
    dispatch({ type: "getUploadDocumentRequest" });

    const { data } = await axios.post(`${apiUrl}/upload-document`, file, {
      withCredentials: true,
    });
    dispatch({
      type: "getUploadDocumentSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "getUploadDocumentFailure",
      payload: error.response.data.message,
    });
  }
};

export const getDocumentStatus = () => async (dispatch) => {
  try {
    dispatch({ type: "getVerificationStatusRequest" });

    const { data } = await axios.get(`${apiUrl}/verification-status`, {
      withCredentials: true,
    });
    dispatch({
      type: "getVerificationStatusSuccess",
      payload: data.document,
    });
  } catch (error) {
    dispatch({
      type: "getVerificationStatusFailure",
      payload: error.response.data.document,
    });
  }
};

export const removeUserAccount = (file) => async (dispatch) => {
  try {
    dispatch({ type: "removeUserAccountRequest" });

    const { data } = await axios.get(`${apiUrl}/remove-account/${file}`, {
      withCredentials: true,
    });
    dispatch({
      type: "removeUserAccountSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "removeUserAccountFailure",
      payload: error.response.data.message,
    });
  }
};
