import React, { useState } from "react";
import "./directServicePage.scss";
import FirstHead from "./components/FirstHead";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginEachPage from "../../components/samplePage/LoginEachPage";
import {
  createDService,
  deleteDService,
  getDService,
} from "../../store/Actions/DirectService";
import AdmissionAssistance from "./forms/AdmissionAssistance";
import Status from "./components/Status";
import Loading from "../../components/samplePage/Loading";
import { directCategories } from "../../DataFiles/data";
import { loadUser } from "../../store/Actions/User";
import { reactUrl } from "../../utils/apiUrl";
import { useTranslation } from "react-i18next";
import HelmetDynamic from "../../utils/HelmetDynamic";
import { useEffectDService } from "./hooks/useEffectDService";
import { useDServiceFunctions } from "./hooks/useDServiceFunctions";
import RoomVerification from "./forms/RoomVerification";
import FooterTop from "../../components/common/FooterTop";

export default function DirectServicePage() {
  const { item } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const dService = useSelector((state) => state.dService);
  const myDService = useSelector((state) => state.myDService);
  const query = new URLSearchParams(location.search);
  const step = query.get("step") === "status" && "status";

  const categoryData = directCategories.find((e) => e.name === item);

  const [services, setServices] = useState({});
  const [error, setError] = useState("");
  const [savedUrl, setSavedUrl] = useState(null);

  const { formSubmit, handleRemove, handleNext } = useDServiceFunctions({
    setError,
    dispatch,
    createDService,
    deleteDService,
    item,
    services,
    dService,
    directCategories,
  });

  useEffectDService({
    dispatch,
    setError,
    loadUser,
    getDService,
    item,
    myDService,
    setSavedUrl,
    step,
    setServices,
    dService,
    services,
    location,
    savedUrl,
    navigate,
  });

  return (
    <>
      <HelmetDynamic
        title={`${item} - Direct Services | StayForAll`}
        description={`Get comprehensive ${item} assistance including expert guidance and reliable support from StayForAll. Join our community today!`}
        url={`${reactUrl}/directServicePage/${item}/?step=status`}
      />

      {user.loading ? (
        <Loading type="servicePage" color="#36d7b7" size={120} />
      ) : !user.isAuthenticated ? (
        <LoginEachPage />
      ) : (
        <div className="directmain font-sans pb-5">
          <div className="backgdirect"></div>
          <FirstHead
            t={t}
            item={item}
            categoryData={categoryData}
            handleNext={handleNext}
          />

          {dService.loading || myDService.loading ? (
            <Loading type="hoverLoading" color="#36d7b7" size={120} />
          ) : step === "status" ? (
            <Status
              t={t}
              item={item}
              handleRemove={handleRemove}
              error={error}
            />
          ) : item === "Admission assistance" ? (
            <AdmissionAssistance
              t={t}
              error={error}
              setError={setError}
              formSubmit={formSubmit}
            />
          ) : item === "Room verification" ? (
            <RoomVerification
              t={t}
              error={error}
              setError={setError}
              formSubmit={formSubmit}
            />
          ) : (
            <p>{t("directServicePage.Service Not Available")}</p>
          )}
          <div className="border-b mt-[160px]"></div>
          <FooterTop bottom="bottom" bgColor="" />
        </div>
      )}
    </>
  );
}
