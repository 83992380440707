import React, { useEffect, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import { Container, Col, Row } from "react-bootstrap";
import { MdDateRange } from "react-icons/md";
import { getNews } from "../../../store/Actions/News";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import { reactUrl } from "../../../utils/apiUrl";
import HelmetDynamic from "../../../utils/HelmetDynamic";

export default function NewsDetailedScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { news } = useSelector((state) => state.news);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(t("infoPage.URL copied to clipboard"));
    }
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    dispatch(getNews(id));
  }, [dispatch, id]);

  return (
    <>
      <HelmetDynamic
        title={`${news?.caption} - StayForAll News`}
        description={
          news?.details?.slice(0, 150) ||
          "StayForAll News - Explore insightful news on various topics."
        }
        url={`${reactUrl}/news-Stayforall/${id}`}
      />

      <Navigation />
      <Container className="min-h-[80vh] border-top border-bottom py-3">
        <Row className="justify-center">
          <Col md={12}>
            <h2 className="newsDetails font-bold p-2">{news?.caption}</h2>
          </Col>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center bg-light p-2 rounded my-3"
          >
            <p className="d-flex align-items-center mb-0">
              <MdDateRange className="mr-2" />{" "}
              {new Date(news?.createdAt).toLocaleDateString()}
            </p>
            <p
              onClick={handleShare}
              className="cursor-pointer d-flex align-items-center mb-0"
            >
              <IoShareOutline className="mr-2" /> {t("infoPage.Share")}
            </p>
          </Col>
          <Col md={12} lg={6}>
            <LazyLoad height={200} offset={100} once>
              <img src={news?.image} alt="News" className="img-fluid rounded" />
            </LazyLoad>
          </Col>
          <Col md={12} className="my-3">
            <p>{news?.details}</p>
          </Col>
        </Row>
      </Container>

      <FooterTop bottom="bottom" />
    </>
  );
}
