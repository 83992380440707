export const useHandleWorkStatus = ({
  dispatch,
  logoutUser,
  navigate,
  toast,
  service,
  setServiceId,
  RemoveInterest,
  setInterestView,
  interests,
  category,
  interest,
  error,
  t,
  setInterests,
  setDeleteId,
}) => {
  const logoutHandler = () => {
    dispatch(logoutUser())
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleNavigation = (status) => {
    navigate(`/myProfile/workStatus?service=${service}&work_status=${status}`);
  };

  const handleServiceId = (id) => {
    setServiceId(id);
  };
  const handleDelete = async (id) => {
    setDeleteId(id);
    dispatch(RemoveInterest(category, id));
  };

  const handleInterestView = (id) => {
    setInterestView(interests.find((e) => e.id == id));
  };

  return {
    logoutHandler,
    handleNavigation,
    handleServiceId,
    handleDelete,
    handleInterestView,
  };
};
