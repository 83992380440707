export const useDServiceFunctions = ({
  setError,
  dispatch,
  createDService,
  deleteDService,
  item,
  services,
  dService,
  directCategories,
}) => {
  // Submit Forms of Direct Service
  const formSubmit = (file) => {
    const isEmptyString = (value) => value === "";
    const hasEmptyValue = Object.values(file).some(isEmptyString);

    if (hasEmptyValue) {
      setError("Enter full details");
    } else {
      dispatch(createDService(file, item));
    }
  };

  // Remove Direct Service
  const handleRemove = () => {
    console.log(services.services.id);
    dispatch(deleteDService(item, services.services.id));
  };

  // Navigate to the next direct service category
  const handleNext = () => {
    const currentIndex = directCategories.findIndex((e) => e.name === item);

    if (currentIndex === 3) {
      window.location.href = `/directServicePage/${directCategories[0].name}?step=status`;
    } else {
      window.location.href = `/directServicePage/${
        directCategories[currentIndex + 1].name
      }?step=status`;
    }
  };

  return {
    formSubmit,
    handleRemove,
    handleNext,
  };
};
