import { configureStore } from "@reduxjs/toolkit";
import {
  loadDocumentVerifyReducer,
  loadUserReducer,
  updateProfileReducer,
  uploadUserDocumentReducer,
  userReducer,
} from "./Reducers/User";
import {
  InDServiceReducer,
  myInDServiceReducer,
  othersInDServiceReducer,
} from "./Reducers/InDirectService";
import { DServiceReducer, myDServiceReducer } from "./Reducers/DirectService";
import {
  interestNotificationReducer,
  interestReducer,
  myInterestReducer,
  othersInterestReducer,
} from "./Reducers/Interest";
import { blogsAndArticleReducer } from "./Reducers/blogsAndArticle";
import { getRatingReducer, ratingReducer } from "./Reducers/Rating";
import { NewsReducer } from "./Reducers/News";
import { WebinarNotifyReducer, WebinarReducer } from "./Reducers/Webinar";
import { DashboardReducer } from "./Reducers/dashboard";
import {
  authChatReducer,
  getAllMessagesReducer,
  messageNotificationReducer,
  sendMessageReducer,
} from "./Reducers/chat";
// import { chatReducer } from "./Reducers/chat";

const store = configureStore({
  reducer: {
    // user reducers
    user: userReducer,
    userInfo: loadUserReducer,
    updateProfile: updateProfileReducer,
    uploadUserDocument: uploadUserDocumentReducer,
    loadDocumentVerify: loadDocumentVerifyReducer,
    // indirect service reducer
    inDService: InDServiceReducer,
    myInDService: myInDServiceReducer,
    othersInDService: othersInDServiceReducer,
    // interest reducer
    interest: interestReducer,
    myInterests: myInterestReducer,
    othersInterests: othersInterestReducer,
    // direct service reducer
    dService: DServiceReducer,
    myDService: myDServiceReducer,
    // blogs and articles
    blogsAndArticle: blogsAndArticleReducer,
    // news
    allNews: NewsReducer,
    // webinars
    allWebinar: WebinarReducer,
    webinarNotify: WebinarNotifyReducer,
    // rating reducer
    rating: ratingReducer,
    myRating: getRatingReducer,
    // dashboard data
    dashboard: DashboardReducer,
    // chat reducer
    getChatReceiver: authChatReducer,
    getMessages: getAllMessagesReducer,
    sendMessage: sendMessageReducer,
    // Notifications
    getMessageNotification: messageNotificationReducer,
    getInterestNotification: interestNotificationReducer,
  },
});

export default store;
