import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const authReceiver = (receiverId) => async (dispatch) => {
  try {
    dispatch({
      type: "authReceiverRequest",
    });

    const { data } = await axios.get(`${apiUrl}/receiverInfo/${receiverId}`, {
      withCredentials: true,
    });

    dispatch({
      type: "authReceiverSuccess",
      payload: data.receiver,
    });
  } catch (error) {
    dispatch({
      type: "authReceiverFailure",
      payload: error.response.data.message,
    });
  }
};

export const getMessages =
  (receiverId, service, interestId) => async (dispatch) => {
    try {
      dispatch({
        type: "getAllMessageRequest",
      });

      const { data } = await axios.get(
        `${apiUrl}/messages/${service}/${receiverId}/${interestId}`,
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "getAllMessageSuccess",
        payload: data?.messages,
      });
    } catch (error) {
      dispatch({
        type: "getAllMessageFailure",
        payload: error.response.data.message,
      });
    }
  };

export const sendMessage =
  (message, service, receiverId) => async (dispatch) => {
    try {
      dispatch({
        type: "sendMessageRequest",
      });

      await axios.post(
        `${apiUrl}/messages/${service}/${receiverId}`,
        { message: message },
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "sendMessageSuccess",
      });
    } catch (error) {
      dispatch({
        type: "sendMessageFailure",
        payload: error.response.data.message,
      });
    }
  };

export const messageNotification = async (dispatch) => {
  try {
    dispatch({
      type: "messageNotificationRequest",
    });

    const { data } = await axios.get(`${apiUrl}/message/notification`, {
      withCredentials: true,
    });
    dispatch({
      type: "messageNotificationSuccess",
      payload: data.unreadConversationsByUser,
    });
  } catch (error) {
    dispatch({
      type: "messageNotificationFailure",
      payload: error.response.data.message,
    });
  }
};
