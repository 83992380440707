import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Navigation from "../../../components/Navigation/Navigation";
import FooterTop from "../../../components/common/FooterTop";
import "../Social.scss";
import { AiOutlineMail } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { getAllNews } from "../../../store/Actions/News";
import { FaRegNewspaper } from "react-icons/fa6";
import Loading from "../../../components/samplePage/Loading";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import { reactUrl } from "../../../utils/apiUrl";
import HelmetDynamic from "../../../utils/HelmetDynamic";

export default function NewsScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const allNews = useSelector((state) => state.allNews);
  const query = new URLSearchParams(location.search);

  const [news, setNews] = useState([]);
  const page = query.get("page") || 1;

  // Change Page Function
  const handleUpdatePage = (e) => {
    query.set("page", e);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  // useEffects
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    dispatch(getAllNews(page));
  }, [page]);

  useEffect(() => {
    setNews(allNews.allNews);
  }, [allNews]);
  return (
    <>
      <HelmetDynamic
        title={`StayForAll News | Latest Updates and Information`}
        description={
          "StayForAll provides the latest news updates and information. Stay tuned for upcoming news articles and announcements."
        }
        url={`${reactUrl}/news-Stayforall?page=${page}`}
      />

      <Navigation />
      <Container className="min-h-[60vh] border-top border-bottom py-2">
        <div className="text-center mt-5 mb-2">
          <h1 className="flex items-center justify-center my-2">
            {t("infoPage.Latest News")} <FaRegNewspaper className="ml-2" />
          </h1>
        </div>

        {/*all news*/}
        <div className="flex flex-wrap min-h-[60vh] border-bottom py-2">
          {allNews.loading ? (
            <Loading type="servicePage" color="#36d7b7" size={120} />
          ) : (
            news?.map((e, i) => (
              <div
                key={`blog${i}`}
                onClick={() => navigate(`/news-Stayforall/${e._id}`)}
                className="blog-each m-2"
              >
                <p>
                  {t("infoPage.News")} {i + 1}
                </p>
                <LazyLoad height={200} offset={100} once>
                  <img className="rounded-t" src={e.image} alt={`News ${i}`} />
                </LazyLoad>

                <div className="border rounded-b px-2">
                  <div>
                    <h4 className="text-[17px] mt-2 flex justify-between">
                      {e.caption.slice(0, 75)}...
                    </h4>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {/*end section*/}
        <div className="mt-3 mb-4 flex justify-between items-center">
          <div
            onClick={() => handleUpdatePage(Math.max(1, Number(page) - 1))}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              page === "1" ? "hidden" : "flex"
            }`}
          >
            <GrLinkPrevious className="mr-2" />
            <span className="font-bold">{t("infoPage.Previous")}</span>
          </div>
          <div>{`${t("infoPage.Page")} ${page}`}</div>
          <div
            onClick={() => handleUpdatePage(Number(page) + 1)}
            className={`cursor-pointer rounded p-2 shadow-md items-center ${
              news?.length < 12 ? "hidden" : "flex"
            }`}
          >
            <span className="font-bold">{t("infoPage.Next")}</span>
            <GrLinkNext className="ml-2" />
          </div>
        </div>
      </Container>

      <FooterTop bottom="bottom" />
    </>
  );
}
