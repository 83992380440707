import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const createInDService = (file, serviceName) => async (dispatch) => {
  try {
    dispatch({
      type: "createInDServiceRequest",
    });

    const { data } = await axios.post(
      `${apiUrl}/service/${serviceName}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "createInDServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "createInDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const UpdateInDService = (file, serviceName, id) => async (dispatch) => {
  try {
    dispatch({
      type: "UpdateInDServiceRequest",
    });

    const { data } = await axios.put(
      `${apiUrl}/service/${serviceName}/${id}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "UpdateInDServiceSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "UpdateInDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const DeleteInDService =
  (serviceName, serviceIdSelect) => async (dispatch) => {
    try {
      dispatch({
        type: "DeleteInDServiceRequest",
      });

      const { data } = await axios.delete(
        `${apiUrl}/service/${serviceName}/${serviceIdSelect}`,
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "DeleteInDServiceSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "DeleteInDServiceFailure",
        payload: error.response.data.message,
      });
    }
  };

export const MyInDService = (serviceName, status) => async (dispatch) => {
  try {
    dispatch({ type: "MyInDServiceRequest" });
    const { data } = await axios.get(
      `${apiUrl}/service/${serviceName}/myService/${status}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "MyInDServiceSuccess",
      payload: data.service,
    });
  } catch (error) {
    dispatch({
      type: "MyInDServiceFailure",
      payload: error.response.data.message,
    });
  }
};

export const OthersInDService =
  (
    service,
    state,
    city,
    uploadDate,
    pageNumber,
    rent,
    securityDeposit,
    moveInDate,
    serviceFee,
    propertyType,
    numberOfBedrooms,
    lengthOfLease,
    roomType,
    utilities,
    airportName,
    arrivalDate,
    payment
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "OthersInDServiceRequest" });
      let airportUrl = `${apiUrl}/service/${service}/othersService?state=${state}&city=${city}&uploadDate=${uploadDate}&pageNumber=${pageNumber}&airportName=${airportName}&arrivalDate=${arrivalDate}&payment=${payment}`;
      let roomUrl = `${apiUrl}/service/${service}/othersService?state=${state}&city=${city}&uploadDate=${uploadDate}&pageNumber=${pageNumber}&rent=${rent}&securityDeposit=${securityDeposit}&moveInDate=${moveInDate}&serviceFee=${serviceFee}&propertyType=${propertyType}&numberOfBedrooms=${numberOfBedrooms}&lengthOfLease=${lengthOfLease}&roomType=${roomType}&utilities=${utilities}`;
      const url = service == "Room" ? roomUrl : airportUrl;

      const { data } = await axios.get(url, {
        withCredentials: true,
      });
      dispatch({
        type: "OthersInDServiceSuccess",
        payload: data.othersServices,
      });
    } catch (error) {
      dispatch({
        type: "OthersInDServiceFailure",
        payload: error.response.data.message,
      });
    }
  };

export const updateNotInterested = (serviceName, id) => async (dispatch) => {
  try {
    dispatch({
      type: "UpdateNotInterestedRequest",
    });

    const { data } = await axios.get(
      `${apiUrl}/service/${serviceName}/notInterested/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "UpdateNotInterestedSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "UpdateNotInterestedFailure",
      payload: error.response.data.message,
    });
  }
};

export const completeService =
  (serviceName, id, interestId) => async (dispatch) => {
    try {
      dispatch({
        type: "CompleteServiceRequest",
      });

      const { data } = await axios.put(
        `${apiUrl}/service/${serviceName}/completeService/${id}/${interestId}`,
        "",
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: "CompleteServiceSuccess",
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: "CompleteServiceFailure",
        payload: error.response.data.message,
      });
    }
  };

export const createInterest = (serviceName, id, file) => async (dispatch) => {
  try {
    dispatch({ type: "CreateInterestRequest" });

    const { data } = await axios.post(
      `${apiUrl}/service/${serviceName}/interest/${id}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "CreateInterestSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "CreateInterestFailure",
      payload: error.response.data.message,
    });
  }
};

export const updateInterest = (serviceName, id, file) => async (dispatch) => {
  try {
    dispatch({ type: "UpdateInterestRequest" });

    const { data } = await axios.put(
      `${apiUrl}/service/${serviceName}/interest/${id}`,
      file,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "UpdateInterestSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "UpdateInterestFailure",
      payload: error.response.data.message,
    });
  }
};

export const RemoveInterest = (serviceName, id) => async (dispatch) => {
  try {
    dispatch({ type: "RemoveInterestRequest" });

    const { data } = await axios.delete(
      `${apiUrl}/service/${serviceName}/interest/${id}`,

      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "RemoveInterestSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "RemoveInterestFailure",
      payload: error.response.data.message,
    });
  }
};

export const interestAccept = (serviceName, id) => async (dispatch) => {
  try {
    dispatch({
      type: "InterestAcceptRequest",
    });

    const { data } = await axios.put(
      `${apiUrl}/service/${serviceName}/acceptInterest/${id}`,
      "",
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "InterestAcceptSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "InterestAcceptFailure",
      payload: error.response.data.message,
    });
  }
};

export const GetMyInterests =
  (serviceName, status, completed) => async (dispatch) => {
    try {
      dispatch({
        type: "GetMyInterestRequest",
      });

      const { data } = await axios.get(
        `${apiUrl}/myInterests/${serviceName}/${status}/${completed}`,
        {
          withCredentials: true,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      dispatch({
        type: "GetMyInterestSuccess",
        payload: data.interests,
      });
    } catch (error) {
      dispatch({
        type: "GetMyInterestFailure",
        payload: error.response.data.message,
      });
    }
  };

export const GetOthersInterests =
  (serviceName, id, accepted) => async (dispatch) => {
    try {
      dispatch({
        type: "GetOthersInterestRequest",
      });

      const { data } = await axios.get(
        `${apiUrl}/othersInterests/${serviceName}/${id}/${accepted}`,
        {
          withCredentials: true,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      dispatch({
        type: "GetOthersInterestSuccess",
        payload: data.interests,
      });
    } catch (error) {
      dispatch({
        type: "GetOthersInterestFailure",
        payload: error.response.data.message,
      });
    }
  };

export const interestNotification = async (dispatch) => {
  try {
    dispatch({
      type: "interestNotificationRequest",
    });

    const { data } = await axios.get(`${apiUrl}/interest/notification`, {
      withCredentials: true,
    });
    dispatch({
      type: "interestNotificationSuccess",
      payload: data.unreadInterests,
    });
  } catch (error) {
    dispatch({
      type: "interestNotificationFailure",
      payload: error.response.data.message,
    });
  }
};
