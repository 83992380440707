import { createReducer } from "@reduxjs/toolkit";

export const NewsReducer = createReducer(
  { loading: false, error: null, allNews: null },
  (builder) => {
    builder
      .addCase("getAllNewsRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.allNews = null;
      })
      .addCase("getAllNewsSuccess", (state, action) => {
        state.loading = false;
        state.allNews = action.payload;
        state.error = null;
      })
      .addCase("getAllNewsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allNews = null;
      })

      .addCase("getNewsRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.allNews = null;
      })
      .addCase("getNewsSuccess", (state, action) => {
        state.loading = false;
        state.allNews = action.payload;
        state.error = null;
      })
      .addCase("getNewsFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allNews = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
