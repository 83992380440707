import { createReducer } from "@reduxjs/toolkit";

export const InDServiceReducer = createReducer(
  {
    loading: false,
    message: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("createInDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("createInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("createInDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("UpdateInDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase(" UpdateInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("UpdateInDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase(" DeleteInDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("DeleteInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("DeleteInDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("CompleteServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("CompleteServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("CompleteServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase("clearMessage", (state) => {
        state.message = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const myInDServiceReducer = createReducer(
  {
    loading: false,
    services: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("MyInDServiceRequest", (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("MyInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("MyInDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("PendingInDServiceRequest", (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("PendingInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("PendingInDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("AcceptedInDServiceRequest", (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("AcceptedInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("AcceptedInDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("CompletedInDServiceRequest", (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("CompletedInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("CompletedInDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const othersInDServiceReducer = createReducer(
  {
    loading: false,
    services: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("OthersInDServiceRequest", (state) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("OthersInDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("OthersInDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
