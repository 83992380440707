import { createReducer } from "@reduxjs/toolkit";

export const DServiceReducer = createReducer(
  {
    loading: false,
    message: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("createDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("createDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("createDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("UpdateDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("UpdateDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("UpdateDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("DeleteDServiceRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("DeleteDServiceSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("DeleteDServiceFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("clearMessage", (state) => {
        state.message = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const myDServiceReducer = createReducer(
  { loading: false, services: null, error: null },
  (builder) => {
    builder
      .addCase("getDServiceRequest", (state, action) => {
        state.loading = true;
        state.services = null;
        state.error = null;
      })
      .addCase("getDServiceSuccess", (state, action) => {
        state.loading = false;
        state.services = action.payload;
        state.error = null;
      })
      .addCase("getDServiceFailure", (state, action) => {
        state.loading = false;
        state.services = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
