import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
import HelmetDynamic from "../../utils/HelmetDynamic";
import { reactUrl } from "../../utils/apiUrl";
import { faqs } from "../../DataFiles/data";
import "./Social.scss";

export default function FAQSPage() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetDynamic
        title="StayForAll FAQs | Common Questions Answered"
        description="Find answers to frequently asked questions about StayForAll services, policies, and more."
        url={`${reactUrl}/FAQs-Stayforall`}
      />
      <Navigation />
      <Container className="py-10">
        <p className="text-4xl text-[#4C787E] mb-5 ml-2">
          {t("infoPage.We'll take care of your doubts")}
        </p>
        <Row>
          <Col lg={6} className="flex justify-center mb-5 lg:mb-0">
            <LazyLoad height={200} offset={100} once>
              <img
                className="h-[370px]"
                alt="FAQs"
                src="/images/infopage/faqs.webp"
              />
            </LazyLoad>
          </Col>
          <Col lg={6} className="flex flex-column justify-center">
            <h2 className="font-bold mb-2">
              {t("infoPage.Frequently Asked Questions")}
            </h2>
            <Accordion bsPrefix="accordionfaqs" defaultActiveKey="0">
              {faqs.map((faq, index) => (
                <Accordion.Item
                  bsPrefix="accordion_item"
                  key={index}
                  eventKey={`${index}`}
                >
                  <Accordion.Header bsPrefix="accordion_head">
                    {t(`infoPage.${faq.question}`)}
                  </Accordion.Header>
                  <Accordion.Body bsPrefix="accordion_body">
                    {t(`infoPage.${faq.answer}`)}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
      <FooterTop bottom="bottom" />
    </>
  );
}
