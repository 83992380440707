import { createReducer } from "@reduxjs/toolkit";

export const interestReducer = createReducer(
  { loading: false, message: null, error: null },
  (builder) => {
    builder
      .addCase("CreateInterestRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("CreateInterestSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("CreateInterestFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("UpdateInterestRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("UpdateInterestSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("UpdateInterestFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("RemoveInterestRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("RemoveInterestSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("RemoveInterestFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("UpdateNotInterestedRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("UpdateNotInterestedSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("UpdateNotInterestedFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("InterestAcceptRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("InterestAcceptSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("InterestAcceptFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase("clearMessage", (state) => {
        state.message = null;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const myInterestReducer = createReducer(
  {
    loading: false,
    interests: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("GetMyInterestRequest", (state) => {
        state.loading = true;
        state.interests = null;
        state.error = null;
      })
      .addCase("GetMyInterestSuccess", (state, action) => {
        state.loading = false;
        state.interests = action.payload;
        state.error = null;
      })
      .addCase("GetMyInterestFailure", (state, action) => {
        state.loading = false;
        state.interests = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const othersInterestReducer = createReducer(
  {
    loading: false,
    interests: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("GetOthersInterestRequest", (state) => {
        state.loading = true;
        state.interests = null;
        state.error = null;
      })
      .addCase("GetOthersInterestSuccess", (state, action) => {
        state.loading = false;
        state.interests = action.payload;
        state.error = null;
      })
      .addCase("GetOthersInterestFailure", (state, action) => {
        state.loading = false;
        state.interests = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const interestNotificationReducer = createReducer(
  { loading: false, error: null, interestNotifications: null },
  (builder) => {
    builder
      .addCase("interestNotificationRequest", (state) => {
        state.loading = true;
        state.interestNotifications = null;
        state.error = null;
      })
      .addCase("interestNotificationSuccess", (state, action) => {
        state.loading = false;
        state.interestNotifications = action.payload;
        state.error = null;
      })
      .addCase("interestNotificationFailure", (state, action) => {
        state.loading = false;
        state.interestNotifications = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
