import { Form, Button, Container, Alert } from "react-bootstrap";
import StepsOrder from "../components/StepsOrder";
import { useState } from "react";
import { t } from "i18next";
import { germanStates } from "../../../DataFiles/countries";
import MapComponent from "../components/MapComponent";
import VerificationReview from "../components/VerificationReview";
import { roomListingData } from "../../../DataFiles/data";
import PhoneInput from "react-phone-input-2";
import { GrLinkPrevious } from "react-icons/gr";

export default function RoomVerification({
  stringStep,
  error,
  setError,
  formSubmit,
}) {
  const [propertyType, setPropertyType] = useState("");
  const [verificationDate, setVerificationDate] = useState("");
  const [verificationTime, setVerificationTime] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [detail, setDetail] = useState("");
  const [landLordNumber, setLandLordNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [preview, setPreview] = useState(false);

  const [stage, setStage] = useState(0);

  const file = {
    propertyType,
    verificationDate,
    verificationTime,
    selectedServices: JSON.stringify(selectedServices),
    detail,
    landLordNumber,
    streetName,
    unitNumber,
    postalCode,
    state,
    city,
    streetAddress,
  };

  // submit the form
  const submitHandler = (e) => {
    e.preventDefault();
    formSubmit(file);
  };

  const isEmptyString = (value) => value === "";

  return (
    <div className="direct2nd">
      <Container className="directcont3">
        {/* Each step */}
        <StepsOrder
          step={stage}
          stringStep={stringStep}
          type={[
            t("directServicePage.Property"),
            t("directServicePage.Schedule"),
            t("directServicePage.Details"),
            t("directServicePage.Finish"),
          ]}
        />

        <Container>
          <Form onSubmit={submitHandler} className="dform_size">
            {stage === 0 && (
              <div className="md:mt-2">
                <h2>
                  {t(
                    "directServicePage.To get started, select the type of property"
                  )}
                </h2>
                <div className="indirect_align gap-4">
                  <div className="p-2 d-grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5">
                    {roomListingData.map((e) => (
                      <div
                        key={e.type}
                        onClick={() => {
                          setPropertyType(e.type);
                          setStage(1);
                        }}
                        className="flex flex-col items-center shadow-md hover:shadow-lg p-1 pt-4 border rounded cursor-pointer"
                      >
                        <img
                          className="w-[80px] h-[80px]"
                          src={`/images/room_listing/${e.type}.webp`}
                          alt={e.type}
                        />
                        <p className="font-bold">
                          {t(`directServicePage.${e.type}`)}
                        </p>
                        <p className="text-center text-[13px] text-secondary">
                          {t(`directServicePage.${e.type} description`)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {stage === 1 && (
              <div className="md:mt-2">
                <div className="flex items-center justify-between">
                  <h3 className="underline">
                    {t(`directServicePage.Schedule Appointment`)}
                  </h3>
                  <div>
                    <Button
                      className="font-bold mr-2"
                      onClick={() => {
                        setStage(0);
                      }}
                    >
                      <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                    </Button>
                    <Button
                      id="second_page_btn"
                      className="text-white font-bold px-5"
                      onClick={() => {
                        if (
                          isEmptyString(verificationDate) ||
                          isEmptyString(verificationTime) ||
                          isEmptyString(selectedServices)
                        ) {
                          setError(
                            `${t("directServicePage.Enter full details")}`
                          );
                        } else {
                          setStage(2);
                          setError("");
                        }
                      }}
                    >
                      {t("directServicePage.Next")}
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="grid lg:grid-cols-2 gap-2">
                    <Form.Group className="my-3">
                      <Form.Label className="font-bold text-[16px] m-0 p-0">
                        {t(`directServicePage.Date for verification visit`)}
                        <span className="text-[red]">*</span>
                      </Form.Label>
                      <div className="border rounded-[15px] px-2 py-1">
                        <Form.Control
                          style={{ border: "none", boxShadow: "none" }}
                          onChange={(e) => setVerificationDate(e.target.value)}
                          value={verificationDate}
                          type="date"
                          required
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="my-3">
                      <Form.Label className="font-bold text-[16px] m-0 p-0">
                        {t(`directServicePage.Time for verification visit`)}
                        <span className="text-[red]">*</span>
                      </Form.Label>
                      <div className="border rounded-[15px] px-2 py-1">
                        <Form.Control
                          style={{ border: "none", boxShadow: "none" }}
                          onChange={(e) => setVerificationTime(e.target.value)}
                          value={verificationTime}
                          type="time"
                          required
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group className="my-3">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.Required Services`)}{" "}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="flex flex-wrap border rounded-[15px] px-2 py-1">
                      {[
                        "Tenant Confirmation",
                        "Rental Contract Validation",
                        "Pre-Move-In Inspection",
                        "Post-Move-Out Inspection",
                        "Legal Requirement (e.g., Anmeldung or proof of residency)",
                        "Condition Assessment (e.g., for insurance or landlord verification)",
                        "Safety Check (e.g., fire alarms, building safety)",
                        "Utility Check (e.g., water, electricity, heating)",
                        "Sublet Approval",
                      ].map((service) => (
                        <Form.Check
                          className="mr-3"
                          key={service}
                          type="checkbox"
                          label={t(`directServicePage.${service}`)}
                          id={`service-${service.replace(/\s+/g, "-")}`}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedServices((prev) => [...prev, service]);
                            } else {
                              setSelectedServices((prev) =>
                                prev.filter((s) => s !== service)
                              );
                            }
                          }}
                        />
                      ))}
                    </div>
                  </Form.Group>
                  <Form.Group className="my-3">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.More Detail`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setDetail(e.target.value)}
                        value={detail}
                        as="textarea"
                        placeholder={t(
                          `directServicePage.Information you needed to know`
                        )}
                        required
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            )}
            {stage === 2 && (
              <div className="md:mt-2">
                <div className="flex items-center justify-between">
                  <h3 className="underline">
                    {t(`directServicePage.Details`)}
                  </h3>
                  <div>
                    <Button
                      className="font-bold mr-2"
                      onClick={() => {
                        setStage(1);
                      }}
                    >
                      <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                    </Button>
                    <Button
                      className="text-white font-bold px-5"
                      onClick={() => {
                        if (
                          isEmptyString(landLordNumber) ||
                          isEmptyString(streetName) ||
                          isEmptyString(postalCode) ||
                          isEmptyString(state) ||
                          isEmptyString(city)
                        ) {
                          setError(
                            `${t("directServicePage.Enter full details")}`
                          );
                        } else {
                          setStage(3);
                          setError("");
                        }
                      }}
                    >
                      {t("directServicePage.Next")}
                    </Button>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 gap-3">
                  <Form.Group className="">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.Landlord contact number`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <PhoneInput
                        country={"de"} // Default country
                        value={landLordNumber}
                        onChange={(value, country) => {
                          setLandLordNumber(value);
                        }}
                        inputStyle={{
                          width: "100%",
                          border: "none",
                          boxShadow: "none",
                        }} // Custom styles for the input field
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.Street Name and Number`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setStreetName(e.target.value)}
                        value={streetName}
                        type="text"
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.Apartment/Unit Number`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setUnitNumber(e.target.value)}
                        value={unitNumber}
                        type="text"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.Postal Code`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setPostalCode(e.target.value)}
                        value={postalCode}
                        type="text"
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.State`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Select
                        style={{ border: "none", boxShadow: "none" }}
                        className="option_choose"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                        aria-label="select state"
                        required
                      >
                        <option
                          className="def_Option"
                          id="state"
                          value=""
                          hidden
                        >
                          {t(`directServicePage.Select your state`)}
                        </option>
                        {germanStates.map((e, i) => (
                          <option
                            value={e.sampleName}
                            key={`preferredState${i}`}
                          >
                            {e.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="font-bold text-[16px] m-0 p-0">
                      {t(`directServicePage.City`)}
                      <span className="text-[red]">*</span>
                    </Form.Label>
                    <div className="border rounded-[15px] px-2 py-1">
                      <Form.Control
                        style={{ border: "none", boxShadow: "none" }}
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                        type="text"
                        required
                      />
                    </div>
                  </Form.Group>
                </div>

                <Form.Group className="col-span-2">
                  <Form.Label className="font-bold text-[15px] m-0 p-0">
                    {t(`directServicePage.Street address`)}
                    <span className="text-[red]">*</span>
                  </Form.Label>
                  <MapComponent
                    streetAddress={streetAddress}
                    setStreetAddress={setStreetAddress}
                  />
                </Form.Group>
              </div>
            )}
            {stage === 3 && (
              <>
                <div className="mb-6">
                  <div className="flex items-center justify-end mb-3">
                    <Button
                      className="font-bold mr-2"
                      onClick={() => {
                        setStage(2);
                      }}
                    >
                      <GrLinkPrevious className="border p-[3px] text-black bg-white rounded-circle text-[22px]" />
                    </Button>
                    <Button type="submit" className="text-white">
                      <span className=" text-[21px]">
                        {t(`directServicePage.Submit for verification`)}
                      </span>
                    </Button>
                  </div>
                  <h3>{t(`directServicePage.Sample verification page`)}</h3>
                  {preview ? (
                    <div className="flex items-center justify-end my-3">
                      <Button onClick={() => setPreview(!preview)}>
                        {t(`directServicePage.Close`)}
                      </Button>
                    </div>
                  ) : (
                    <div className="bgImgVerification h-[340px] flex items-center justify-center bg-[grey] my-3">
                      <Button onClick={() => setPreview(!preview)}>
                        {t(`directServicePage.Preview`)}
                      </Button>
                    </div>
                  )}
                  {preview && <VerificationReview />}
                </div>
              </>
            )}
            {error && (
              <Alert variant="danger" className="col-span-2 mt-3">
                {error}
              </Alert>
            )}
          </Form>
        </Container>
      </Container>
    </div>
  );
}
