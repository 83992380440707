import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const getAllBlogsAndArticles = (page) => async (dispatch) => {
  try {
    dispatch({
      type: "getallBlogsAndArticleRequest",
    });

    const { data } = await axios.get(`${apiUrl}/blogsAndArticles/${page}`, {
      withCredentials: true,
    });
    dispatch({
      type: "getallBlogsAndArticleSuccess",
      payload: data.blogsAndArticles,
    });
  } catch (error) {
    dispatch({
      type: "getallBlogsAndArticleFailure",
      payload: error.response.data.message,
    });
  }
};

export const getBlogsAndArticles = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getBlogsAndArticleRequest",
    });

    const { data } = await axios.get(
      `${apiUrl}/blogsAndArticleSelected/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getBlogsAndArticleSuccess",
      payload: data.blogsAndArticles,
    });
  } catch (error) {
    dispatch({
      type: "getBlogsAndArticleFailure",
      payload: error.response.data.message,
    });
  }
};
