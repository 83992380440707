import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DeleteInDService,
  RemoveInterest,
  completeService,
  createInDService,
  interestAccept,
} from "../../../store/Actions/InDirectService";

export const useServiceFunctions = ({
  category,
  serviceName,
  serviceIdSelect,
  services,
  setServiceIdSelect,
  setServiceInterests,
  dispatch,
  setError,
  setInterestStatusSelect,
  setAccepted,
  accepted,
  query,
  location,
  setNewService,
  setServiceView,
  setInterestIdSelect,
  serviceInterests,
  setInterestView,
  interestIdSelect,
  setCompleteSelect,
  completeSelect,
}) => {
  const navigate = useNavigate();

  const handleServiceDelete = () => {
    console.log(category);
    dispatch(DeleteInDService(category, serviceIdSelect));
  };

  const handleSwitchChange = () => {
    setError("");
    setInterestStatusSelect(accepted ? "pending" : "accepted");
    setAccepted(!accepted);
    // query.set("status", accepted ? "pending" : "accepted");
    // navigate(`${location.pathname}?${query.toString()}`);
  };

  const createFormHandler = async (file) => {
    await dispatch(createInDService(file, category));
  };

  const handleUpdateStatus = (e) => {
    setError("");
    localStorage.removeItem(`explain${serviceName}`);
    setNewService(true);
    query.set("status", e);
    navigate(`${location.pathname}?${query.toString()}`);
  };

  const handleServiceIdSelect = (e) => {
    setError("");
    setServiceIdSelect(e);
  };

  const handleServiceView = (id) => {
    setError("");
    setServiceView(services.find((e) => e.id === id));
  };

  const handleInterestView = (id) => {
    setError("");
    setInterestIdSelect(id);
    setInterestView(serviceInterests.find((e) => e.id === id));
  };

  const handleCompleteSelect = () => {
    setError("");
    setCompleteSelect(!completeSelect);
  };

  const handleServiceComplete = (selectedInterest) => {
    setError("");
    dispatch(completeService(category, serviceIdSelect, selectedInterest));
  };

  const handleAcceptInterest = () => {
    setError("");
    console.log(interestIdSelect);
    dispatch(interestAccept(category, interestIdSelect));
  };

  const handleRejectInterest = () => {
    setError("");
    dispatch(RemoveInterest(category, interestIdSelect));
  };

  const resetServiceState = () => {
    setError("");
    setServiceIdSelect("");
    setServiceInterests("");
  };

  return {
    handleServiceDelete,
    handleSwitchChange,
    createFormHandler,
    handleUpdateStatus,
    handleServiceIdSelect,
    handleServiceView,
    handleInterestView,
    handleCompleteSelect,
    handleServiceComplete,
    handleAcceptInterest,
    handleRejectInterest,
    resetServiceState,
  };
};
