import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LocationSearchInput = ({ onSelect }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchText(query);

    if (query.trim() === "") {
      setResults([]);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          query
        )}&countrycodes=de`
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error fetching results:", error);
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectResult = (result) => {
    onSelect(result);
    setSearchText(result.display_name);
    setResults([]);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setResults([]);
  };

  return (
    <div className="relative">
      <div className="border rounded-[15px] px-2 py-1 mb-3">
        <Form.Control
          type="text"
          className="border-0 shadow-none"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Enter location"
          aria-label="Location Search Input"
        />
      </div>

      {loading && (
        <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
          ...
        </div>
      )}
      {searchText && (
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white border rounded p-1 cursor-pointer"
          type="button"
          onClick={handleClearSearch}
          aria-label="Clear Search"
        >
          {t("directServicePage.Clear")}
        </button>
      )}

      {results.length > 0 && (
        <ul className="mt-2 border rounded-lg p-2">
          {results.map((result) => (
            <li
              key={result.place_id}
              className="cursor-pointer p-1 hover:bg-gray-200"
              onClick={() => handleSelectResult(result)}
            >
              {result.display_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearchInput;
