import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import InDirectCategories from "./components/InDirectCategories";
import FooterTop from "../../components/common/FooterTop";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import { reactUrl } from "../../utils/apiUrl";
import HelmetDynamic from "../../utils/HelmetDynamic";
import DirectCategory from "./components/DirectCategory";
import "./HomeScreen.scss";

export default function HomeScreen() {
  const { t } = useTranslation();

  return (
    <>
      <HelmetDynamic
        title="StayForAll - Room, Airport Assistance, Admission"
        description="StayForAll offers services like room listings, airport assistance, room verification, and student admission. Join our global community today!"
        url={`${reactUrl}/`}
      />

      <Navigation />
      <Header t={t} />
      <div id="services">
        <InDirectCategories t={t} />
        <DirectCategory t={t} />
      </div>
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
