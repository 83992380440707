import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer(
  {
    loading: false,
    error: null,
    isAuthenticated: false,
  },
  (builder) => {
    builder
      .addCase("loginRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase("loginSuccess", (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase("loginFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
      })

      .addCase("logoutRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.isAuthenticated = true;
      })
      .addCase("logoutSuccess", (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase("logoutFailure", (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload;
      })

      .addCase("registerRequest", (state) => {
        state.loading = true;
        state.isAuthenticated = false;
        state.error = null;
      })
      .addCase("registerSuccess", (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
      })
      .addCase("registerFailure", (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload;
      })

      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const loadUserReducer = createReducer(
  {
    loading: false,
    user: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("loadUserRequest", (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase("loadUserSuccess", (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase("loadUserFailure", (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.payload;
      })
      .addCase("getUserRequest", (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase("getUserSuccess", (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase("getUserFailure", (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);

export const updateProfileReducer = createReducer(
  {
    loading: false,
    message: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("updateProfileRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("updateProfileSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("updateProfileFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("changePasswordMailSendRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("changePasswordMailSendSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("changePasswordMailSendFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("updatePasswordRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("updatePasswordSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("updatePasswordFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("removeUserAccountRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("removeUserAccountSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("removeUserAccountFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })

      .addCase("clearErrors", (state) => {
        state.error = null;
      })
      .addCase("clearMessage", (state) => {
        state.message = null;
      });
  }
);

export const uploadUserDocumentReducer = createReducer(
  {
    loading: false,
    message: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("getUploadDocumentRequest", (state) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("getUploadDocumentSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("getUploadDocumentFailure", (state, action) => {
        state.loading = false;
        state.message = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      })
      .addCase("clearMessage", (state) => {
        state.message = null;
      });
  }
);

export const loadDocumentVerifyReducer = createReducer(
  {
    loading: false,
    document: null,
    error: null,
  },
  (builder) => {
    builder
      .addCase("getVerificationStatusRequest", (state) => {
        state.loading = true;
        state.document = null;
        state.error = null;
      })
      .addCase("getVerificationStatusSuccess", (state, action) => {
        state.loading = false;
        state.document = action.payload;
        state.error = null;
      })
      .addCase("getVerificationStatusFailure", (state, action) => {
        state.loading = false;
        state.document = null;
        state.error = action.payload;
      })
      .addCase("clearErrors", (state) => {
        state.error = null;
      });
  }
);
