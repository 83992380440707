import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DirectCategory = ({ t }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center mb-8">
      <Row className="shadow-lg py-2 m-3 admissionHome">
        <Col>
          <img
            src="https://images.unsplash.com/photo-1504785992718-b22f4f7205db?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Study in Germany"
            className="w-full rounded-lg"
          />
        </Col>
        <Col className="flex flex-col justify-center p-4">
          <div className="flex items-center mb-2">
            <img
              src="/images/logo/SFAsmallLogo.webp"
              alt="Stayforall Logo"
              className="w-5 mr-2"
            />
            <p className="text-sm text-gray-600">
              {t("homePage.Sponsored by Stayforall")}
            </p>
          </div>
          <h2 className="text-2xl font-bold mb-3">
            {t("homePage.Study in Germany")}
          </h2>
          <p className="text-sm text-gray-700 mb-4">
            {t("homePage.Direct description")}
          </p>
          <button
            onClick={() => navigate("/directServicePage/Admission assistance")}
            className="bg-black text-white py-2 px-4 rounded-lg font-bold text-nowrap hover:scale-105"
          >
            {t("homePage.APPLY NOW")}{" "}
            <span className="bg-white text-black py-1 px-[10px] ml-2 rounded-circle">
              ❯
            </span>
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(DirectCategory);
