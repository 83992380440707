import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const getAllNews = (page) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllNewsRequest",
    });

    const { data } = await axios.get(`${apiUrl}/news/${page}`, {
      withCredentials: true,
    });
    dispatch({
      type: "getAllNewsSuccess",
      payload: data.allNews,
    });
  } catch (error) {
    dispatch({
      type: "getAllNewsFailure",
      payload: error.response.data.message,
    });
  }
};

export const getNews = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getNewsRequest",
    });

    const { data } = await axios.get(`${apiUrl}/newsSelected/${id}`, {
      withCredentials: true,
    });
    dispatch({
      type: "getNewsSuccess",
      payload: data.news,
    });
  } catch (error) {
    dispatch({
      type: "getNewsFailure",
      payload: error.response.data.message,
    });
  }
};
