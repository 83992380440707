import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Container,
  Card,
  ListGroup,
  Modal,
  Offcanvas,
} from "react-bootstrap";
import Gallery from "react-image-gallery";
import { MdOutlineVerified, MdOutlineDateRange } from "react-icons/md";
import { FaStar, FaMapMarkerAlt } from "react-icons/fa";
import { AiFillEuroCircle } from "react-icons/ai";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { TiTickOutline } from "react-icons/ti";
import {
  FaCreditCard,
  FaCcVisa,
  FaCcAmex,
  FaCcStripe,
  FaCcApplePay,
} from "react-icons/fa6";

export default function VerificationReview() {
  const [showModal, setShowModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const handleShowMore = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    document.body.style.overflow = "auto"; // Re-enable scrolling when the modal is closed
  };

  const handleCloseProperty = () => {
    document.body.style.overflow = "auto"; // Re-enable scrolling when the modal is closed

    setShowPropertyModal(false);
  };

  let serviceView = {
    owner: "Niyas km",
    rating: "4.5/5",
    availableFrom: "12-May-2023",
    monthlyRent: "450$",
    securityDeposit: "1300$",
    utilitiesIncluded: true,
    address: "Friedrichbrunner Strabe",
    amenities: [
      "Entire apartment",
      "Property : 15m²",
      "Furnished",
      "Space for 1 person",
      "1 bedroom",
      "flexible cancellation",
    ],
    registrationInfo:
      "You can register at this address (anmeldung). Depending on how long you’re staying in Germany, you may have to register your house address at the local citizens’ office (Bürgeramt).",
    roomDescription:
      "There are rooms for rent in Neukölln. The shared bathroom has a shower over a large bath, as well as a sink and toilet, while the modern kitchen has an oven and plenty of cupboard space. There are a few shops within a 10-minute walk of this apartment, including a supermarket and post office. There are a couple of restaurants as well, while the large Tempelhofer Feld is easily accessible by train or bus.",
    additionalDetails: {
      propertyType: "Apartment",
      bathrooms: 1,
      elevator: false,
      wifiInstalled: true,
      energyEfficiency: "Energieausweis available",
    },
    facilities: [
      "Private balcony",
      "Unisex bathroom",
      "Shared toilet",
      "Shared kitchen",
    ],
    amenitiesList: [
      "Unknown flooring",
      "Central heating",
      "Shared kitchenware",
      "WiFi",
    ],
    neighborhoodInfo:
      "Located in a vibrant neighborhood with easy access to public transportation (U-Bahn, bus stops). Nearby amenities include supermarkets, restaurants, and parks.",
    culturalInfo:
      "Experience local culture with nearby events and community activities. The neighborhood is known for its cultural diversity and lively atmosphere.",
    rulesAndPreferences: {
      age: "At least 18",
      gender: "No preference",
      tenantType: "Students, working professionals",
      couplesAllowed: true,
      musicalInstrumentsAllowed: "Negotiable",
      petsAllowed: false,
      smokingAllowed: false,
    },
    documentsRequired: {
      proofOfIdentity: "Government issued ID, passport, driver’s license.",
      proofOfIncome: "Salary slip or bank statements from you or your sponsor",
      proofOfEnrollment:
        "University enrollment certificate, internship or employment contract",
    },
    contractType: "Monthly",
    cancellationPolicy: {
      within24Hours: "Full refund of first month’s rent",
      moreThan30Days: "Full refund of first month’s rent",
      thirtyToSevenDays: "50% refund of first month’s rent",
      lessThanSevenDays: "No refund",
    },
    paymentDetails: {
      monthlyRent: "€1285",
      securityDeposit: "€1928.00",
      administrationFee: "€150.00",
      utilities: {
        electricity: "Included",
        gas: "Included",
        internet: "Included",
        water: "Included",
      },
      paymentMethods: [
        { name: "Credit Card", icon: FaCreditCard },
        { name: "Visa", icon: FaCcVisa },
        { name: "Amex", icon: FaCcAmex },
        { name: "Stripe", icon: FaCcStripe },
        { name: "Apple Pay", icon: FaCcApplePay },
      ],
    },
    fullPropertyDetails: {
      room: "Your studio apartment offers all the privacy you need. In addition to your very own bedroom area, which features a comfortable bed and plenty of storage, your private studio also features a fully equipped modern kitchen and spacious bathroom. You won't need anything additional to settle in, and can arrive with just your luggage at hand!",
      building:
        "Located in the heart of the exciting Europacity, our modern building makes Berlin living even more special. This brand new construction features a community space in the ground floor, where members can meet up, network, or host small events like game nights or cocktail hour. You will also have access to a beautiful courtyard, the perfect place to escape from busy city living and enjoy a moment of stillness.",
      neighborhood:
        "An entirely new part of Berlin, Europacity is an urban destination with comfortable environments, several amenities, and relaxing green and open spaces. Located in the northern part of Berlin, in the district of Mitte, Europacity is closely located to the Central Station, making it easy and convenient to reach other parts of the city such as Alexanderplatz, Brandenburger Gate and Friedrichshain in less than 20 minutes. With an area of approximately 61 hectares, Europacity offers all the amenities you’d expect from the city center, in addition to relaxing green and open spaces.",
    },
    images: [
      {
        original:
          "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
        thumbnail:
          "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
      },
      {
        original:
          "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
        thumbnail:
          "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
      },
      {
        original: "https://volzero.com/img/article/100939_banner.jpg",
        thumbnail: "https://volzero.com/img/article/100939_banner.jpg",
      },
    ],
  };

  const handleBooking = () => {
    // handle booking logic
  };

  return (
    <>
      <Container fluid className="p-4">
        <Row>
          <Col md={8}>
            <Gallery
              items={[
                "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
                "https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2023/6/1/house-middleclass.jpg",
                "https://volzero.com/img/article/100939_banner.jpg",
              ].map((image) => ({
                original: image,
                thumbnail: image,
              }))}
            />
          </Col>
          <Col md={4}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Property Details</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex align-items-center">
                    <MdOutlineVerified className="text-success mr-2" />
                    <span>{serviceView.owner}</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <FaStar className="text-warning mr-2" />
                    {serviceView.rating}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span className="d-flex align-items-center">
                      <MdOutlineDateRange className="mr-2" />
                      Available from:
                    </span>
                    <span className="font-weight-normal ml-5">
                      {serviceView.availableFrom}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex align-items-center">
                    <AiFillEuroCircle className="mr-2" />
                    Monthly rent: {serviceView.monthlyRent}
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => setShowPayment(!showPayment)}
                    className="d-flex align-items-center"
                  >
                    <HiOutlineClipboardDocumentList className="mr-2" />
                    View what you'll pay
                  </ListGroup.Item>
                  <Offcanvas
                    show={showPayment}
                    onHide={() => {
                      setShowPayment(!showPayment);
                      document.body.style.overflow = "auto";
                    }}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Payment details</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div className="mb-4">
                        <h3 className="text-md font-medium">
                          You → Stayforall
                        </h3>
                        <div className="flex justify-between items-center my-2">
                          <span>Monthly rent</span>
                          <span>{serviceView.paymentDetails.monthlyRent}</span>
                        </div>
                        <div className="flex space-x-2 mt-4">
                          {serviceView.paymentDetails.paymentMethods.map(
                            (method, index) => (
                              <method.icon size={24} key={index} />
                            )
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex justify-between items-center my-2">
                          <span>Security deposit</span>
                          <span>
                            {serviceView.paymentDetails.securityDeposit}
                          </span>
                        </div>
                        <div className="flex justify-between items-center my-2">
                          <span>Administration fee</span>
                          <span>
                            {serviceView.paymentDetails.administrationFee}
                          </span>
                        </div>
                        <div className="flex justify-between items-center my-2">
                          <span>Electricity</span>
                          <span>
                            {serviceView.paymentDetails.utilities.electricity}
                          </span>
                        </div>
                        <div className="flex justify-between items-center my-2">
                          <span>Gas</span>
                          <span>
                            {serviceView.paymentDetails.utilities.gas}
                          </span>
                        </div>
                        <div className="flex justify-between items-center my-2">
                          <span>Internet</span>
                          <span>
                            {serviceView.paymentDetails.utilities.internet}
                          </span>
                        </div>
                        <div className="flex justify-between items-center my-2">
                          <span>Water</span>
                          <span>
                            {serviceView.paymentDetails.utilities.water}
                          </span>
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                </ListGroup>
                {/* <div className="text-center mt-3">
                  <Button variant="primary" onClick={handleBooking}>
                    Book now
                  </Button>
                </div> */}
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Amenities</Card.Title>
                <ListGroup variant="flush">
                  {serviceView.amenities.map((amenity, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex align-items-center"
                    >
                      <TiTickOutline className="text-success mr-2" />
                      {amenity}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Registration Info</Card.Title>
                <Card.Text>{serviceView.registrationInfo}</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Room Description</Card.Title>
                <Card.Text>{serviceView.roomDescription}</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Additional Details</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Property Type: {serviceView.additionalDetails.propertyType}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Bathrooms: {serviceView.additionalDetails.bathrooms}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Elevator:{" "}
                    {serviceView.additionalDetails.elevator ? "Yes" : "No"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    WiFi Installed:{" "}
                    {serviceView.additionalDetails.wifiInstalled ? "Yes" : "No"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Energy Efficiency:{" "}
                    {serviceView.additionalDetails.energyEfficiency}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Facilities</Card.Title>
                <ListGroup variant="flush">
                  {serviceView.facilities.map((facility, index) => (
                    <ListGroup.Item key={index}>{facility}</ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Neighborhood Information</Card.Title>
                <Card.Text>{serviceView.neighborhoodInfo}</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Cultural Information</Card.Title>
                <Card.Text>{serviceView.culturalInfo}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Rules and Preferences</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Age: {serviceView.rulesAndPreferences.age}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Gender: {serviceView.rulesAndPreferences.gender}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Tenant Type: {serviceView.rulesAndPreferences.tenantType}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Couples Allowed:{" "}
                    {serviceView.rulesAndPreferences.couplesAllowed
                      ? "Yes"
                      : "No"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Musical Instruments:{" "}
                    {serviceView.rulesAndPreferences.musicalInstrumentsAllowed}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Pets Allowed:{" "}
                    {serviceView.rulesAndPreferences.petsAllowed ? "Yes" : "No"}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Smoking Allowed:{" "}
                    {serviceView.rulesAndPreferences.smokingAllowed
                      ? "Yes"
                      : "No"}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Documents Required</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Proof of Identity:{" "}
                    {serviceView.documentsRequired.proofOfIdentity}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Proof of Income:{" "}
                    {serviceView.documentsRequired.proofOfIncome}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Proof of Enrollment:{" "}
                    {serviceView.documentsRequired.proofOfEnrollment}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Contract Type</Card.Title>
                <Card.Text>{serviceView.contractType}</Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Cancellation Policy</Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Within 24 Hours:{" "}
                    {serviceView.cancellationPolicy.within24Hours}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    More than 30 Days:{" "}
                    {serviceView.cancellationPolicy.moreThan30Days}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    30 to 7 Days:{" "}
                    {serviceView.cancellationPolicy.thirtyToSevenDays}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Less than 7 Days:{" "}
                    {serviceView.cancellationPolicy.lessThanSevenDays}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Button
              variant="primary"
              onClick={() => setShowPropertyModal(true)}
            >
              View Full Property Details
            </Button>
            <Modal
              show={showPropertyModal}
              onHide={handleCloseProperty}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Full Property Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Room</Card.Title>
                    <Card.Text>
                      {serviceView.fullPropertyDetails.room}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Building</Card.Title>
                    <Card.Text>
                      {serviceView.fullPropertyDetails.building}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Neighborhood</Card.Title>
                    <Card.Text>
                      {serviceView.fullPropertyDetails.neighborhood}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseProperty}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
}
