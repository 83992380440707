import React from "react";
import { BsCalendarDate } from "react-icons/bs";
import { IoCashOutline } from "react-icons/io5";
import { BsBuildings } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { CiTimer } from "react-icons/ci";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { GiBunkBeds } from "react-icons/gi";
import { inDirectCategories } from "../../DataFiles/data";
import { useTranslation } from "react-i18next";

export default function RoomView({ serviceView, handleServiceId }) {
  const { t } = useTranslation();
  return (
    <>
      {/* view the room details */}
      {serviceView && (
        <div className="popup-view">
          <div className="popView ">
            <p className="flex items-center mx-3 mt-3 font-bold">
              <img
                className="w-[60px]"
                src={inDirectCategories.Room.smallicon1}
                alt="Room"
              />
              <span className="ml-2">
                {t("inDirectServicePage.Room")}{" "}
                {t("inDirectServicePage.Details")}
              </span>
            </p>

            <div className="m-3">
              {/* location */}
              <h3 className="flex items-center font-bold">
                <BsBuildings className="text-[19px] mx-2 text-secondary" />
                {t(`inDirectServicePage.${serviceView.propertyType}`)}
              </h3>
              <p className="ml-5">
                {serviceView.city}, {serviceView.state}
              </p>
              <p className="mt-2 pt-2 border-t">
                {t("inDirectServicePage.I am looking for a")}{" "}
                {t(
                  `inDirectServicePage.${serviceView?.roomType}`
                ).toLowerCase()}{" "}
                {serviceView?.numberOfBedrooms}{" "}
                {t(
                  `inDirectServicePage.${serviceView?.propertyType}`
                ).toLowerCase()}{" "}
                {t("inDirectServicePage.in")} {serviceView?.city.toLowerCase()},{" "}
                {t("inDirectServicePage.available from")}{" "}
                {new Date(serviceView?.moveInDate).toLocaleDateString("en-GB", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })}
                . {t("inDirectServicePage.My budget for rent is")}
                {serviceView.rent}{" "}
                {t("inDirectServicePage.per month, with a security deposit of")}{" "}
                {serviceView.securityDeposit},{" "}
                {t("inDirectServicePage.and a service fee")}{" "}
                {serviceView.serviceFee}.{" "}
                {t("inDirectServicePage.I need a lease term of")}{" "}
                {t(`inDirectServicePage.${serviceView.lengthOfLease}`)},{" "}
                {t("inDirectServicePage.and utilities should")}{" "}
                {!serviceView.utilities && t("inDirectServicePage.not")}{" "}
                {t("inDirectServicePage.be included")}.
              </p>
            </div>

            <div className="m-3 border rounded grid md:grid-cols-3 sm:grid-col-1 items-center bg-white">
              {/* rent */}
              <div className="flex items-center md:justify-center py-2 text-[16px] md:border-r border-grey">
                <IoCashOutline className="text-[21px] mx-2 text-secondary translate-y-[-20px]" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.Rent")}</span>
                  <br />€{serviceView.rent}
                </p>
              </div>

              {/* security deposit */}
              <div className="flex items-center md:justify-center py-2 text-[16px] md:border-r border-grey">
                <IoCashOutline className="text-[21px] mx-2 text-secondary translate-y-[-20px]" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.Security deposit")}</span>
                  <br />€ {serviceView.securityDeposit}
                </p>
              </div>

              {/* Service fee */}
              <div className="flex items-center md:justify-center py-2 text-[16px]">
                <IoCashOutline className="text-[21px] mx-2 text-secondary translate-y-[-20px]" />
                <p className="text-[17px] font-bold text-secondary mr-2">
                  <span>{t("inDirectServicePage.Service fee")}</span>
                  <br />€ {serviceView.serviceFee}
                </p>
              </div>
            </div>

            <div className="border-t border-b border-grey py-2 px-3">
              <h4>
                {t("inDirectServicePage.Room")}{" "}
                {t("inDirectServicePage.Details")}
              </h4>
              <div className="flex flex-wrap">
                {/* number of bedrooms */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <GiBunkBeds className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Number of bedrooms")}
                    </span>
                    <br />
                    {serviceView.numberOfBedrooms}
                  </p>
                </div>
                {/* room type */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <MdOutlineMeetingRoom className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Room type")}
                    </span>
                    <br />
                    {t(`inDirectServicePage.${serviceView.roomType}`)}
                  </p>
                </div>
                {/* utilities */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <GiBunkBeds className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Rent include utilities")}
                    </span>
                    <br />
                    {t(`inDirectServicePage.${serviceView.utilities}`)}
                  </p>
                </div>
              </div>
            </div>

            <div className="border-b border-grey py-2 px-3">
              <h4>{t("inDirectServicePage.Essential details")}</h4>
              <div className="flex flex-wrap">
                {/* move in date */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <BsCalendarDate className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Move in date")}
                    </span>
                    <br />
                    {new Date(serviceView.moveInDate).toLocaleDateString(
                      "en-GB",
                      {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                  </p>
                </div>
                {/* length of lease */}
                <div className="flex items-center bg-white border rounded-[40px] mx-3 px-2 my-1">
                  <CiTimer className="border rounded-circle text-[40px] mx-2 p-1" />
                  <p className="font-bold mr-2">
                    <span className="text-[15px] text-secondary">
                      {t("inDirectServicePage.Length of lease")}
                    </span>
                    <br />
                    {t(`inDirectServicePage.${serviceView.lengthOfLease}`)}
                  </p>
                </div>
              </div>
            </div>

            {/* short detail */}
            {serviceView.detail && (
              <div className="col-span-2 py-2 px-3 rounded text-[16px] pb-5">
                <span className="text-[17px] font-bold text-secondary mr-2">
                  {t("inDirectServicePage.More details")}
                </span>
                <br />
                {serviceView.detail}
              </div>
            )}
            <div onClick={() => handleServiceId("")} className="closeBtn">
              <ImCancelCircle className=" hover:scale-105" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
