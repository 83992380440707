import { Col, Container, Row, Nav } from "react-bootstrap";
import { BsArrowUpRight, BsArrowRight } from "react-icons/bs";
import { FiArrowDownRight } from "react-icons/fi";

export default function FirstHead({ t, item, categoryData, handleNext }) {
  return (
    <div className="direct1st">
      <Container className="directcont1">
        <Row className="m-0 p-0">
          <Col
            className="directsection1"
            style={{
              backgroundImage: `url(${categoryData.contsection})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "20px",
            }}
          >
            <div className="d-flex align-items-center mb-3">
              <img
                alt="SFA small logo"
                src="/images/logo/SFAsmallLogo.webp"
                className="w-10 mr-2"
                loading="lazy"
              />
              <div>
                <p className="text-xs m-0">
                  Stayforall {t("directServicePage.Helps")}
                </p>
                <p className="text-xs m-0">
                  {t("directServicePage.Students and Workers")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <span className="badge bg-grey text-xs text-white mr-2">
                  {t("directServicePage.our expertise")}
                </span>
                <span className="badge bg-grey text-xs text-white">
                  {t("directServicePage.services")}
                </span>
              </div>
              <BsArrowUpRight className="icon bg-black text-white p-1 rounded" />
            </div>
          </Col>
          <Col className="directsection2">
            <Nav className="d-flex justify-content-between align-items-center mt-2">
              <Nav.Item className="d-flex">
                <Nav.Link href="/" className="underline">
                  {t("directServicePage.Home")}
                </Nav.Link>
                <Nav.Link href="/about-us-Stayforall">
                  {t("directServicePage.About")}
                </Nav.Link>
                <Nav.Link href="/FAQS-Stayforall">
                  {t("directServicePage.Faq")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-flex align-items-center">
                <Nav.Link
                  href="tel:+918075540817"
                  className="border rounded-3xl px-2"
                >
                  <span className="text-xs">
                    {t("directServicePage.CONTACT US")}
                  </span>
                </Nav.Link>
                <Nav.Link>
                  <BsArrowRight
                    onClick={handleNext}
                    className="icon bg-warning text-white p-1 rounded"
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="d-flex align-items-start justify-content-end mr-5 mt-3">
              <img
                className="w-9 mr-3"
                src="/images/logo/SFAsmallLogo.webp"
                alt="SFA logo"
                loading="lazy"
              />
              <h1 className="m-0">
                {t("directServicePage.Stayforall helps you with")} <br />
                {t(`directServicePage.${item}`)}
              </h1>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <p className="w-50 text-start text-secondary text-xs mr-5">
                <img
                  style={{ width: "24px" }}
                  src="/images/directPage/celebration.webp"
                  alt="celebration"
                  loading="lazy"
                  className="mr-1"
                />
                {t(`directServicePage.${categoryData.smallDiscription}`)}
              </p>
            </div>
            <img
              className="w-100 mt-2"
              src="/images/inDirect/inDHead.webp"
              alt="inDHead"
              loading="lazy"
            />
            <div className="text-start ml-5 mt-3">
              <FiArrowDownRight className="icon bg-green-600 p-1 m-2 rounded" />
              <h6 className="m-0">
                {t(`directServicePage.${item}`)}{" "}
                {t("directServicePage.made possible with Stayforall")}
              </h6>
              <p className="w-50 text-start text-secondary text-xs mr-5 mt-1">
                {t("directServicePage.Please fill out the form provided below")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
