import React from "react";
import Navigation from "../../components/Navigation/Navigation";
import FooterTop from "../../components/common/FooterTop";

export default function Error404Page() {
  return (
    <>
      {/* Navigation */}
      <Navigation />
      <div className="oops h-[80vh] p-3 flex flex-column items-center justify-center">
        <h1>Oops</h1>
        <h3>404 - PAGE NOT FOUND</h3>
        <p className="text-center">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
      </div>
      {/* footer */}
      <FooterTop top="top" bottom="bottom" />
    </>
  );
}
