import { createReducer } from "@reduxjs/toolkit";

export const ratingReducer = createReducer(
  {
    loading: false,
    error: null,
    message: null,
  },
  (builder) => {
    builder
      .addCase("createRatingRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.message = false;
      })
      .addCase("createRatingSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("createRatingFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = false;
      })
      .addCase("updateRatingRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.message = false;
      })
      .addCase("updateRatingSuccess", (state, action) => {
        state.loading = false;
        state.message = action.payload;
        state.error = null;
      })
      .addCase("updateRatingFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = false;
      });
  }
);

export const getRatingReducer = createReducer(
  {
    loading: false,
    error: null,
    rating: null,
  },
  (builder) => {
    builder
      .addCase("getMyRatingRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.rating = null;
      })
      .addCase("getMyRatingSuccess", (state, action) => {
        state.loading = false;
        state.rating = action.payload;
        state.error = null;
      })
      .addCase("getMyRatingFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.rating = null;
      })
      .addCase("getAllRatingRequest", (state) => {
        state.loading = true;
        state.error = null;
        state.rating = null;
      })
      .addCase("getAllRatingSuccess", (state, action) => {
        state.loading = false;
        state.rating = action.payload;
        state.error = null;
      })
      .addCase("getAllRatingFailure", (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.rating = null;
      });
  }
);
